import axios from 'axios'
import React, { useCallback } from 'react'
import { apiBaseUrl, apiKey } from '../config'
import { ccMasterPhase } from '../types/ccTypes'
import { orderType } from '../types/order'
//@ts-ignore
import managerBlack from "../tracker/manager_black.png";
//@ts-ignore
import installBlack from "../tracker/install_black.png";
//@ts-ignore
import internetBlack from "../tracker/internet_black.png";
//@ts-ignore
import truckBlack from "../tracker/truck_black.png";
//@ts-ignore
import signedBlack from "../tracker/signed_black.png";
//@ts-ignore
import sfBlack from "../tracker/sf_black.png";
//@ts-ignore
import ConfiguredBlack from "../tracker/configured_black.png";
//@ts-ignore
import BoughtBlack from "../tracker/bought_black.png";
//@ts-ignore
import checkBlack from "../tracker/checked_black.png";
//@ts-ignore
import losantBlack from "../tracker/losantlogo_black.png";
//@ts-ignore
import bomBlack from "../tracker/BOM_black.png";
//@ts-ignore
import networkBlack from "../tracker/network_black.png";
//@ts-ignore
import testBlack from "../tracker/test_black.png";
import { displayDate } from '../displayDate'
const displayText = require('./displayText.json')

interface OrderBoxProps {
    topic: string
    order: orderType
    edit?: boolean
    phase?: ccMasterPhase
    set_order: any
}

export default function OrderboxV2({topic, order, edit, set_order }: OrderBoxProps) {
    let divStyle = { display: "flex",  width: '400px', margin: "5px", borderRadius: "5px", paddingLeft: "5px"}
    const imgStyle = {width: '35px', margin: "5px"}
    let date = null
    const green = {backgroundColor: "#4CBF72"}
    const subject = displayText?.filter( (t:any) => t.topic === topic)?.[0]
    const topicStatus = order?.todoList?.filter(task => task.topicName === topic)?.[0]
    if(topicStatus?.completed){
        divStyle = {...divStyle, ...green}
        date = topicStatus?.completed
    }
    const attachImg = (image: string) => {
        if(image === "BoughtBlack") return BoughtBlack  
        if(image === "checkBlack") return checkBlack  
        if(image === "losantBlack") return losantBlack  
        if(image === "managerBlack") return  managerBlack
        if(image === "installBlack") return  installBlack
        if(image === "internetBlack") return  internetBlack
        if(image === "truckBlack") return  truckBlack
        if(image === "signedBlack") return  signedBlack
        if(image === "sfBlack") return  sfBlack
        if(image === "ConfiguredBlack") return  ConfiguredBlack
        if(image === "bomBlack") return  bomBlack
        if(image === "networkBlack") return  networkBlack
        if(image === "testBlack") return  testBlack
        return 
    }
    const image = attachImg(displayText?.filter( (t:any) => t.topic === topic)?.[0]?.image)

    const update = useCallback( async () => {
        try{
            const d = new Date()
            let newValue = null
            if(!topicStatus?.completed){
                newValue = d.getTime()
            }
            const res= await axios.put(`${apiBaseUrl}/api/v0/order/${order.id}`, {topic, date: newValue } ,{  headers: { 'x-api-key': apiKey}})
            set_order(res.data)
        }catch(err){
          console.log("err",err)
        }
      },[order.id, set_order,topic,topicStatus?.completed])

  return (
    <div style={{...divStyle} }> 
        <img alt="logo" style={imgStyle} src={image}/>  
        <p style={{margin: "10px"}}> {subject?.displayText}</p>
        { edit ? <input  style={{margin: "10px"}} 
                type="checkbox"
                disabled={edit ? false : true} 
                checked={date? true: false} 
                onChange={update}></input> : null}
        <p style={{margin: "10px"}}>{displayDate(date)}</p>
    </div>
  )
}
