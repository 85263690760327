import axios from "axios";
import React, { useCallback, useState } from "react";
import { Button, Checkbox, Input, Table } from "semantic-ui-react";
import { apiBaseUrl, apiKey } from "../config";
import { displayDate } from "../displayDate";
import { orderAction, orderType } from "../types/order";
import ReactDatePicker from "react-datepicker";
interface OrderActionsProps {
    order: orderType;
    set_order: any
  }

export default function OrderActions({ order,set_order }: OrderActionsProps) {
  const [action, set_action] = useState("")
  const [owner, set_owner] = useState("")
  const [checked, set_checked] = useState(false)
  const today = new Date()
  const [dueDate, set_dueDate] = useState<number|undefined>(today.getTime())
  const d = new Date()
  d.setHours(12)
  const date = d.getTime()
  
  const addComment  = useCallback( async () => {
    try{
        if(action && owner && dueDate ){
            const add = {action, owner , date, dueDate: dueDate} 
            const res= await axios.put(`${apiBaseUrl}/api/v0/order/${order.id}`, add ,{  headers: { 'x-api-key': apiKey}})
            set_order(res.data)
            if(res?.status === 200){
              set_action("")
              set_owner("")
            }
        }
    }catch(err){
      console.log("err",err)
    }
  },[set_order, order.id, date, action,owner, dueDate])

  const markCompleted = async (action: orderAction) => {
    if(action){
      const add = { action: action.action, 
                    owner: action.owner , 
                    date: action.date, 
                    dueDate: action.dueDate,
                    markComplete: true
                  }
      const res= await axios.put(`${apiBaseUrl}/api/v0/order/${order.id}`, add ,{  headers: { 'x-api-key': apiKey}})
      set_order(res.data)
    }
  }
  const reviseDueDate = async (action: orderAction, date: Date | null) => {
    if(action && date){
      const add = { action: action.action, 
                    owner: action.owner , 
                    date: action.date, 
                    dueDate: action.dueDate,
                    revisedDueDate: date,
                    updateDueDate: true
                  }
      const res= await axios.put(`${apiBaseUrl}/api/v0/order/${order.id}`, add ,{  headers: { 'x-api-key': apiKey}})
      set_order(res.data)
    }
  }
  const actions = checked ?  order?.actions:  order?.actions?.filter(oc => !oc.completed) 
  const showdate = dueDate ? new Date(dueDate) : new Date()
  const edit = localStorage.getItem("edit")
  return (
    <div>
      <h2>Actions</h2>
      <p>Outstanding tasks that are waiting to be completed</p>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date recorded</Table.HeaderCell>
            <Table.HeaderCell>Action owner</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
            <Table.HeaderCell>Due Date</Table.HeaderCell>
            { edit ?<Table.HeaderCell>Revised Due Date</Table.HeaderCell> : null }
            { edit ? <Table.HeaderCell><Checkbox label="Show completed" checked={checked} onChange={()=> set_checked(!checked)}/></Table.HeaderCell> : null }
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {actions.map((oc, i) => {
            const d = new Date(oc?.date)
            const revisedDate = oc?.revisedDueDate ? new Date(oc?.revisedDueDate) : new Date(oc?.dueDate)
            return (
              <Table.Row key={i}>
                <Table.Cell>{displayDate(d)}</Table.Cell>
                <Table.Cell>{oc?.owner}</Table.Cell>
                <Table.Cell>{oc?.action}</Table.Cell>
                <Table.Cell>{displayDate(oc?.dueDate)}</Table.Cell>
                { edit ? <Table.Cell>{!oc?.completed ?  <ReactDatePicker selected={revisedDate} 
                                                                onChange={(e)=> reviseDueDate(oc, e)}
                                                                dateFormat="dd/MM/yyyy" /> : null}
                                                                </Table.Cell> : null }
                { edit ? <Table.Cell>{!oc?.completed ? <Button onClick={()=>markCompleted(oc)}>Mark Completed</Button> : null}</Table.Cell> : null }
              </Table.Row>
            );
          })}

          {edit ? <Table.Row>
            <Table.Cell width={1}>
              {displayDate(date)}
              </Table.Cell>
            <Table.Cell width={1}>
              <Input value={owner} onChange={(e) => set_owner(e?.target?.value)} style={{width: "100%"}}/>
            </Table.Cell>
            <Table.Cell>
              <Input value={action} onChange={(e) => set_action(e?.target?.value)} style={{width: "100%"}}/>
            </Table.Cell>
            <Table.Cell colSpan="2">
              <ReactDatePicker selected={showdate} onChange={(e)=> set_dueDate(e?.getTime())} dateFormat="dd/MM/yyyy" />
              {/* <ReactDatePicker selected={showdate} onChange={changeTimer} dateFormat="dd/MM/yyyy" /> */}
          
            </Table.Cell>
            <Table.Cell width={2}>
                <Button primary onClick={addComment}>Add</Button>
            </Table.Cell>
          </Table.Row>: null}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="6"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
}
