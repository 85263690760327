import React, { useCallback, useEffect, useState } from 'react'
import EquipmentStats from './EquipmentStats'
import { apiBaseUrl, apiKey } from '../config'
import axios from 'axios'
import { useParams } from 'react-router-dom'

export default function EquipmentUseageStatsOverview() {
  const [stats, set_stats] = useState<any>([]) //TODO type of array
  const params = useParams()
  
  const getStats = useCallback( async (id:string) => {
      const url = `${apiBaseUrl}/api/v0/devices/operation/stats?deviceId=${id}`
      const result = await axios.get(url,{  headers: { 'x-api-key': apiKey}}) 
      set_stats(result?.data)
  },[])
  useEffect( () => {
    if(params?.id){
      getStats(params?.id) 
    }
  }, [getStats, params?.id])

  return (
    <React.Fragment>
      <div className='equipmentUseageStatsHeader'>
        <h3>Unit totals and averages</h3>
        <div className='equipmentUseageStatsSince'>
          <div className='equipmentUseageStatsSince'>
            <span className='equipmentUseageStatsHeaderIcon'/>
            <p className="equipmentUseageStatsHeaderInfoText">Since: {stats?.metadata?.since}</p>
          </div>
          <p className="equipmentUseageStatsHeaderInfoText">Last updated: {stats?.metadata?.infoUpdated}</p>
          </div>
      </div>
      <hr/>
    <div className='equipmentUseageStatsHolder'>
      {
        stats?.items?.map((a:any, i: number) => <EquipmentStats 
          key={i}
          title={a.title} 
          value={a.value} 
          unit={a.unit} 
          leaf={a.icon === "leaf"} 
          updated={a.updated} 
          description={a.description} />)
      }
    </div>
    </React.Fragment>
  )
}
