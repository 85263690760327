import React from 'react'
import CompanyLogo from './CompanyLogo'
import HeaderMenuIcon from './HeaderMenuIcon'
import HeaderMenuItem from './HeaderMenuItem'

export default function Header() {
  return (
    <div className='headerSection'>
        <CompanyLogo link=''/>
        <div className='headerMenuItemHolder'>
            <HeaderMenuItem name="Your products" link="devices"/>
            <HeaderMenuItem name="Shop"/>
            <HeaderMenuItem name="Maintenace"/>
        </div>
        <div className='headerIconHolder'>
            <HeaderMenuIcon type="shopping" />
            <HeaderMenuIcon type="profile"/>
        </div>
    </div>
  )
}

