import React, { useCallback } from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Table } from "semantic-ui-react";
//@ts-ignore
import { useParams } from "react-router-dom";
// import { deviceInfo as deviceInfoType } from "../types/deviceInfo";
import { apiBaseUrl } from "../config";
import { localTagInfo } from "../types/tagInfo";

export default function LocalTableListDetails() {
    const [localTaglist, set_localTaglist] = useState<localTagInfo[]>([]);
    const query = useParams();
  const getTaglistLocal = useCallback ( async () => {
    try {
      const url = `${apiBaseUrl}/api/v0/device/taglist/local/${query?.deviceId}/${query?.table}`;
      const res = await axios.get(url);
      set_localTaglist(res.data);

      //dispatch({ type: 'SET_DATA', newData: res.data })
    } catch (err) {
      console.log("error", err);
    }
  },[query?.deviceId, query?.table]) 

  useEffect(() => {
    getTaglistLocal();
  }, [getTaglistLocal]);
    // if (data === null) return <p>Loading</p>;
  // console.log(data)
  return (
    <div className="App">
      <h1>CC-losant-agent-fetcher {query?.deviceId}</h1>

      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>tagId</Table.HeaderCell>
            <Table.HeaderCell>Tagpath</Table.HeaderCell>
            <Table.HeaderCell>local occurence in db </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {localTaglist?.map((e: localTagInfo, i: number) => (
            <Table.Row key={i}>
            <Table.Cell>{e?.tagId || e?.id}</Table.Cell>
            <Table.Cell>{e?.tagpath}</Table.Cell>
            <Table.Cell>{e?.count}</Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="7"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
}

