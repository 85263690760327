import React from "react";

interface headerMenuIconProps {
  type: "profile" | "shopping";
}

export default function HeaderMenuIcon({ type }: headerMenuIconProps) {
  return (
    <svg
      className="headerMenuIcon"
      width="28"
      height="19"
      viewBox="0 0 28 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {type === "shopping" ? (
        <path
          d="M8.39399 4.6656V6.53093H26.1146V10.2234L24.0453 16.7903H7.80711L5.47545 0H0V1.86533H3.85121L6.18288 18.6556H25.4133L27.98 10.5103V4.6656H8.39399Z"
          fill="#0091D3"
        />
      ) : null}
      {type === "profile" ? (
        <path
          d="M16.3302 15.793L12.8981 13.5622L14.192 11.19C14.4937 10.6361 14.6519 10.0154 14.6524 9.38469V5.62945C14.6524 4.22954 14.0963 2.88696 13.1064 1.89708C12.1165 0.907187 10.7739 0.351074 9.37402 0.351074C7.97411 0.351074 6.63153 0.907187 5.64164 1.89708C4.65176 2.88696 4.09564 4.22954 4.09564 5.62945V9.38469C4.09611 10.0155 4.25437 10.6361 4.55599 11.1901L5.8499 13.5622L2.41787 15.793C1.89041 16.1344 1.45701 16.6027 1.15732 17.155C0.857623 17.7072 0.701214 18.3258 0.702399 18.9542V22.9727H18.0456V18.9542C18.0468 18.3258 17.8904 17.7072 17.5907 17.155C17.291 16.6027 16.8576 16.1344 16.3302 15.793ZM16.5375 21.4646H2.21051V18.9542C2.2098 18.5771 2.30366 18.206 2.48348 17.8746C2.6633 17.5433 2.92335 17.2623 3.23984 17.0574L7.8424 14.0658L5.87997 10.4679C5.699 10.1355 5.60404 9.76316 5.60375 9.38469V5.62945C5.60375 4.62951 6.00097 3.67053 6.70804 2.96347C7.4151 2.25641 8.37408 1.85918 9.37402 1.85918C10.374 1.85918 11.3329 2.25641 12.04 2.96347C12.7471 3.67053 13.1443 4.62951 13.1443 5.62945V9.38469C13.144 9.76316 13.049 10.1355 12.8681 10.4679L10.9057 14.0658L15.5083 17.0574C15.8248 17.2623 16.0848 17.5433 16.2646 17.8746C16.4444 18.206 16.5383 18.5772 16.5375 18.9542V21.4646Z"
          fill="#0091D3"
        />
      ) : null}
    </svg>
  );
}
