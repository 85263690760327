import React, { useEffect, useState } from 'react'
import axios from 'axios'
import EquipmentStatusIcon, { equipmentIconType } from './EquipmentStatusIcon'
import { apiBaseUrl, apiKey } from '../config'

export interface operationalState {
    "deviceId": equipmentIconType,
    "name": string
    "connectionInfo": { connected: null | 1 | 0},
    "gpsLocation": string
}

export const operationalIconColor = (r:operationalState):equipmentIconType => {
    return r?.connectionInfo?.connected === 1 ? "green" : "red"
}
export default function EquipmentList() {
    const [data, set_data] = useState<operationalState[]>([])
    const getData = async () => {
        const url = `${apiBaseUrl}/api/v0/equipment`
        try{
            
            const result = await axios.get(url, {  headers: { 'x-api-key': apiKey}})    
            if(result?.data)
                set_data(result?.data)
            else{
                set_data([])
            }
        }catch(err){
            console.log("err", err)
        }
    }
    useEffect( () => {
        getData()
    }, [])
    
  return (
    <div className="equipmentOperationalHistorySection">
        <table style={{width: "100%", backgroundColor: "white"}}>
            <thead>
                <th>Online</th>
                <th align='left'>Name</th>
                <th align='left'>Location</th>
                <th align='left'>Status</th>
                <th align='left'>Last known state</th>
            </thead>
            <tbody>
                {data.map( (r,i) => {
                    //@ts-ignore
                    const icon:equipmentIconType =  operationalIconColor(r)
                    return ( <tr key={i}>
                            <td><EquipmentStatusIcon iconType={icon || "gray"} size="small"/></td>
                            <td><a href={`/devicedetails/${r.deviceId}`}>{r.name}</a></td>  
                            <td>{`Unkown Location`}</td>  
                            <td><EquipmentStatusIcon iconType={"charging"} size="small"/></td>  
                            <td>{`Last state unknown`}</td>  
                        </tr>
                    )
                })
                }
            </tbody>
        </table>
        <hr/>
        {/* <p className='showMoreButton'>{"Show full history >"}</p> */}
    </div>
  )
}
