import React from 'react'
import TopSectionMenuItem from './TopSectionMenuItem'

export default function TopSectionMenu() {
  return (
    <div className='topSectionMenu'>TopSectionMenu
        <TopSectionMenuItem/>
    </div>
  )
}
