import React from "react";
import { apiResponseOrderStatsType } from "../types/order";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList
} from "recharts";
const displayText = require('./displayText.json')

interface OrderStatsProps {
  orderStats: apiResponseOrderStatsType  | null;
}

export default function OrderStats({ orderStats }: OrderStatsProps) {
  
  console.log("info", orderStats?.taskStats)
  const orderTaskCompletionData = orderStats?.taskStats?.map( stat => {
    const name = displayText?.filter( (t:any) => t.topic === stat?.name)?.[0]
    if(!name){

      console.log("name", name, stat)
    }
    return {  name:  name?.displayText, 
              completed: stat?.completed, 
              open: stat?.open
    }
  })
 
  
  const orderPercentageData = [
    {
      name: "0-20%",
      uv: orderStats?.orderStats?.untilTwenty,
    },
    {
      name: "21-40%",
      uv: orderStats?.orderStats?.untilFourty,
    },
    {
      name: "41-60%",
      uv: orderStats?.orderStats?.untilSixy,
    },
    {
      name: "61-80%",
      uv: orderStats?.orderStats?.untilEighty,
    },
    {
      name: "81-99%",
      uv: orderStats?.orderStats?.untilNineNine,
    },
    {
      name: "100%",
      uv: orderStats?.orderStats?.complete,
    }//,
    // {
    //   name: "All orders",
    //   uv: orderStats?.orderStats?.count,
    // },
  ];

  const colors = ["red", "#0088FE", "#FFBB28", "#FF8042", "pink", "#00C49F"];

  return (
    <div style={{display: "flex", justifyContent: "", flexDirection:"column"}}>
      <h1>OrderStats</h1>
      <div style={{display: "flex" , flexDirection:"row", flexWrap:"wrap"}}>
      <BarChart
        width={500}
        height={300}
        data={orderPercentageData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Bar dataKey="uv" fill="#8884d8" label={{ position: "top" }}>
          {orderPercentageData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
          ))}
        </Bar>
      </BarChart>
      <div  style={{display: "flex" , flexDirection:"row", flexWrap:"wrap"}}>
        <ul>
          {orderStats?.pmStats?.map((stat, i) => <li key={i}>{`${stat?.name}(${stat?.count})`}</li>)}
        </ul>
        <ul>
          {orderStats?.locationStats?.map( (stat,i) => <li key={i}>{`${stat?.location}(${stat?.count})`}</li>)}
        </ul>
        <ul>
          {orderStats?.designTypeStats?.map( (stat,i) => <li key={i}>{`${stat?.designType}(${stat?.count})`}</li>)}
        </ul>
      </div>

      <BarChart
        width={900}
        height={300}
        data={orderTaskCompletionData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" hide/>
        <YAxis />
        <Bar dataKey="completed" stackId="a" fill="#8884d8"  label />
        <LabelList
      dataKey="name"
      position="insideBottom"
      angle={270}
      offset={25}
      fill="black"
    />
        <Bar dataKey="open" stackId="a" fill="#82ca9d" label />
        <Tooltip />
        <Legend />
      </BarChart>
      </div>
    </div>
  );
}
