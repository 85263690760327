import React from "react";
import { deviceInfo as deviceInfoType } from "../types/deviceInfo";

interface deviceListprops {
  deviceId: string | null | undefined;
  unitNumber: string | number | null;
  deviceInfo: deviceInfoType | null
}
function TagListPage({
  unitNumber,
  deviceInfo
}: deviceListprops) {
  if (deviceInfo === null) return <p>Loading</p>;
  return (
    <div className="App">
      <h2>{deviceInfo.deviceName} </h2>
      <h2>Guessed unitnumber: {unitNumber}</h2>
    </div>
  );
}

export default TagListPage;
