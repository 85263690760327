import React from 'react'
import {pageList} from '../types/pageList'
interface headerMenuItemProps {
    name: string
    link?: pageList
}

export default function HeaderMenuItem({name, link}:headerMenuItemProps) {
  return (
    <a href={`/${link}`} className='headerMenuItem'>{name}</a>
  )
}
