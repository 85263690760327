import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { parseDate, timeSince } from '../parseDate'
import { deviceInfo,deviceData } from '../types/device'
import { operationalState } from './EquipmentOperationalHistory'
import EquipmentStatusIndicator from './EquipmentStatusIndicator'
import { apiBaseUrl, apiKey } from "../config";

export default function EquipmentStatusHeader() {

  const [states, set_states] = useState<operationalState[]>([])
  const [info, set_info] = useState<deviceInfo>()
  const [data, set_data] = useState<deviceData>()
  const params = useParams()
  const getStates = useCallback( async (id:string) => {
      const url = `${apiBaseUrl}/api/v0/devices/operation/states?deviceId=${id}&limit=${2}`
      const result = await axios.get(url,{  headers: { 'x-api-key': apiKey}})    
      set_states(result?.data)
  },[])

  const getInfo = useCallback( async (id:string) => {
      const url = `${apiBaseUrl}/api/v0/devices/info?deviceId=${id}`
      const result = await axios.get(url) 
      set_info(result?.data)
  },[])
  const getData = useCallback( async (id:string) => {
      const url = `${apiBaseUrl}/api/v0/device/data?deviceId=${id}`
      const result = await axios.get(url) 
      set_data(result?.data)
  },[])

  useEffect( () => {
    if(params?.id){
      getStates(params?.id) 
      getInfo(params?.id)
      getData(params?.id)
    }
  }, [getStates,getInfo,getData, params?.id])

  const machineState =  info?.connectionInfo?.connected ? data?.['cc-level-1']? data?.['cc-level-1'] : "State unkown" : "Offline"
  const machineStateInfo =  info?.connectionInfo?.connected ? data?.['cc-level-2'] ? data?.['cc-level-2'] : "online" : `Since ${parseDate(info?.connectionInfo?.time)}`
  const iconColor =  info?.connectionInfo?.connected ? "green" : "red"
  return (
    <div className="equipmentStatusHeader">
        <div className="statusIndicatorSection">
            <EquipmentStatusIndicator icon={states?.[0]?.icon || "gray"} state={states?.[0]?.stateName} info={timeSince(states?.[0]?.stateStart)|| "Duration unknown"}/>
            <EquipmentStatusIndicator icon={iconColor} state={machineState} info={machineStateInfo}/>
        </div>
        <div className="MetaDataSection">
          <p className="MetaDataSectionData MetaDataSectionLastUpdated">Last updated: <span style={{ display: "inline-block"}}>{parseDate(info?.lastUpdated) || "unknown" }</span></p>
          <p className="MetaDataSectionData">Serial nr: {info?.tags?.serialNumber}</p>
          <p className="MetaDataSectionData">Type: {info?.tags?.display_type} </p>
          <p className="MetaDataSectionData">Capacity: {info?.tags?.display_capacity}</p>
          {info?.tags?.display_IMO ? <p className="MetaDataSectionData">Vessel IMO: {info?.tags?.display_IMO}</p> : null }
        </div>
    </div>
  )
}


