import React from 'react'
import EquipmentStatusIcon, { equipmentIconType } from './EquipmentStatusIcon'

interface equipmentStatusIndicatorProps {
    icon: equipmentIconType
    state: string
    info: string
}

export default function EquipmentStatusIndicator({state,info, icon}:equipmentStatusIndicatorProps) {
  return (
    <div className="equipmentStatusIndicator">
        <div className='equipmentStatusIndicatorIconSection'>
            <EquipmentStatusIcon iconType={icon}/>
        </div>
        <div className='equipmentStatusIndicatorTextSection'>
            <h1 className='equipmentStatusIndicatorTextSectionState'>{state}</h1>
            <p className='equipmentStatusIndicatorTextSectionText'>{info}</p>
        </div>
    </div>
  )
}
