export const displayDate = (d: any) => {
    if(!d) return ""
    let date = d
    if(typeof(d)=== "number"){
        date = new Date(d)
    }
    if(typeof(d) === "boolean") return "no date"
    //above is needed when there is nothing in a order... Error is backend
    return  `${addLeadingZero(date?.getDate())}-${addLeadingZero(date?.getMonth()+1)}-${date?.getFullYear()}`
}
const addLeadingZero = (num: number) => {
    return pad(num, 2)
}
function pad(inputNumber : number, size: number) {
    let num = inputNumber.toString();
    while (num.length < size) num = "0" + num;
    return num;
}