import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Dropdown, Input, Table } from "semantic-ui-react";
import { apiBaseUrl, apiKey } from "../config";
import { abbreviationType, dropdownProductOption } from "../types/order";
import { orderType } from "../types/order";
import { lookupProductNameOnId } from "./IdlookUp";
interface orderContentProps {
    order: orderType;
    set_order: any
  }

export default function OrderContents({ order,set_order }: orderContentProps) {
  const [productType, set_productType] = useState("");
  const [orderCount, set_orderCount] = useState(1);
  const [productOptions, set_productOptions] = useState<dropdownProductOption[]>([]) 
  const [abbreviations, set_abbreviations] = useState([])
  
  const getAbbreviations = useCallback(async () => {
        try {
          const res = await axios.get(`${apiBaseUrl}/api/v0/abbreviations`, {
            headers: { "x-api-key": apiKey },
          });
          set_abbreviations(res?.data);
        } catch (err) {
          console.log("err", err);
        }
      }, []);

      
  useEffect(() => {
    getAbbreviations();
  }, [getAbbreviations]);

  useEffect(()=>{
    //this code is 1-1 copied somewhere else
    const productOptions: dropdownProductOption[] = []
    abbreviations?.forEach((abb: any) => {
        abb?.types?.forEach( (type:abbreviationType) => {
            const option = {
                key: `${type?.id}`,
                value: `${type?.id}`,
                text: `${abb.fullProductName} (${type?.name})`,
            }
            productOptions.push(option)
        })
    })
    set_productOptions(productOptions)
}, [abbreviations])

  const addProduct  = useCallback( async () => {
    try{
        if(productType && orderCount){
            const add = {orderCount, productType} 
            const res= await axios.post(`${apiBaseUrl}/api/v0/order/${order.id}`, add ,{  headers: { 'x-api-key': apiKey}})
            set_order(res.data)
        }
    }catch(err){
      console.log("err",err)
    }
  },[productType, orderCount, set_order, order.id])

  const edit = localStorage.getItem("edit")
  return (
    <div>
      <h2>OrderContents</h2>
      <p>In this section you can add the contents of this project, what equipment does Cavotec supply?</p>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Count</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {order?.orderContents?.map((oc,i) => {
            return (
              <Table.Row key={i}>
                <Table.Cell>{oc?.count}</Table.Cell>
                <Table.Cell>{lookupProductNameOnId(oc?.id, abbreviations)}</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            );
          })}
          {edit ? 
          <Table.Row>
            <Table.Cell >
              <Input
                iconPosition="left"
                id="dropdownSearch"
                value={orderCount}
                className="search"
                onChange={(e, { value }) => set_orderCount(Number(value))}
                //   onKeyPress={handleKeyPress}
              />
            </Table.Cell>
            <Table.Cell>
              <Dropdown
                placeholder="Select product type"
                fluid
                search
                selection
                options={productOptions}
                value={productType}
                style={{ width: "300px" }}
                onChange={(e, { value }) =>
                  set_productType(value?.toString() || "")
                }
              />
            </Table.Cell>
            <Table.Cell width={2}>
                <Button primary onClick={addProduct}>Add</Button>
            </Table.Cell>
          </Table.Row>
          : null }
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="4"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
}
