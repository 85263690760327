import React, { useCallback, useEffect, useState } from "react";
// import dummyData from "../dummyData/dailyOverview.json";
import { apiBaseUrl } from "../config";
import { operationStateSummary } from "./EquipmentOperationalHistory";
import axios from "axios";
import { useParams } from "react-router-dom";

export default function EquipmentOperationalHistory() {
  const [data, set_data] = useState<operationStateSummary[]>([])
  const params = useParams();

  const getData = useCallback(async (id: string) => {
    const url = `${apiBaseUrl}/api/v0/devices/operation/summary?deviceId=${id}`;
    const result = await axios.get(url);
    set_data(result?.data);
    // set_data([...dummyData, ...result?.data]); 
    // left this here, so we can look what info we are missing
  }, []);

  useEffect(() => {
    if (params?.id) getData(params?.id);
  }, [params?.id, getData]);
  return (
    <div className="equipmentOperationalHistorySection">
      <h3>Daily overview</h3>
      <table>
        <thead>
          <th>Date</th>
          <th>Charging cycles</th>
          <th>Charging time </th>
          <th>Missed connections</th>
          <th>Aborted connections</th>
          {/* <th>uptime</th> */}
          {/* <th>Downtime</th> */}
          {/* <th>Alerts</th> */}
        </thead>
        <tbody>
          {data.map((r, i) => {
            return (
              <tr key={i}>
                <td>{r.date}</td>
                <td>{r.chargingCyles}</td>
                <td>{r.chargingTime}</td>
                <td>{r.missedConnections}</td>
                <td>{r.abortedConnections}</td>
                {/* <td>{r.uptime}</td> */}
                {/* <td>{r.downtime}</td> */}
                {/* <td>{r.alerts}</td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <hr /> */}
      <p className="showMoreButton">{"Show full history >"}</p>
    </div>
  );
}
