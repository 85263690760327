import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Table } from "semantic-ui-react";
import { apiBaseUrl, apiKey } from "../config";
import { useParams } from "react-router-dom";
import { ssmResponse } from "../types/ssm";

export default function OrderSSMDetailsPage() {
  const [ssm, set_ssm] = useState<null | ssmResponse>(null);
  const params = useParams();
  const getSSM = useCallback(async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/api/v0/ssm/${params?.id}`, {
        headers: { "x-api-key": apiKey },
      });
      set_ssm(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  }, [params?.id]);

  useEffect(() => {
    getSSM();
  }, [getSSM]);

  return (
    <div>
      <a
        style={{ color: "black", fontWeight: "bold" }}
        target="_blank"
        rel="noreferrer"
        href={`https://eu-west-1.console.aws.amazon.com/systems-manager/fleet-manager/managed-nodes/${ssm?.instanceInformation?.InstanceId}/general?region=eu-west-1`}
      >
        Link to the SSM Info in the AWS console
      </a>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Computername</Table.HeaderCell>
            <Table.HeaderCell>InstanceId</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {ssm?.tagList?.map((a, i: number) => {
            return (
              <React.Fragment key={i}>
                <Table.Row>
                  <Table.Cell>{a?.Key}</Table.Cell>
                  <Table.Cell>{a?.Value}</Table.Cell>
                </Table.Row>
              </React.Fragment>
            );
          })}
          <Table.Row>
            {" "}
            <Table.Cell colSpan="2"> </Table.Cell>
          </Table.Row>
          {Object.keys(ssm?.instanceInformation || {})?.map((a, i: number) => {
            return (
              <React.Fragment key={i}>
                <Table.Row>
                  <Table.Cell>{a}</Table.Cell>
                  {/* 
                                //@ts-ignore */}
                  <Table.Cell>{ssm?.instanceInformation[a]}</Table.Cell>
                </Table.Row>
              </React.Fragment>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="4"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
}
