import React, { useCallback } from "react";
import Orderbox from "./Orderbox";
import { Icon, Label, Tab } from "semantic-ui-react";
import { orderType, designType } from "../types/order";
import axios from "axios";
import { apiBaseUrl, apiKey } from "../config";
import OrderStep from "./OrderStep";
import { lookupProductNameOnId } from "./IdlookUp";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { displayDate } from "../displayDate";
import { useShowOrEdit } from "./ShowOrEdit";
interface orderProps {
  order: orderType;
  siteName: string;
  edit?: boolean;
  showDetails?: boolean;
  set_order?: any;
  abbreviations?: any
}

export default function Order({
  order,
  edit,
  showDetails,
  set_order,
  abbreviations
}: orderProps) {
  

  const holder = { display: "flex", flexDirection: "column" };
  const countProducts = (order: orderType) => {
    let count = 0;
    for (let i = 0; i < order?.orderContents?.length; i++) {
      count += order?.orderContents?.[i]?.count;
    }
    return count;
  };
  const designTypeLabel = (designType: designType, changeFn: any) => {
    const cb = (event: any) => {
      if (event.detail === 2) {
        changeFn()
      }
    };
    if (designType === "New build") {
      return (
        <Label tag style={{ width: "150px" }} color="blue" onClick={cb}>
          New build
        </Label>
      );
    }
    if (designType === "Retrofit") {
      return (
        <Label tag style={{ width: "150px" }} color="orange" onClick={cb}>
          Retrofit
        </Label>
      );
    }
    return (
      <Label tag style={{ width: "150px" }} color="red" onClick={cb}>
        {" "}
        New build / retrofit unknown
      </Label>
    );
  };

 
  const changeOrderType = useCallback(async () => {
    if(!localStorage.getItem("edit")){
      return null
    }
    try {
      let newValue: designType = "New build";
      if (order?.designType === "New build") {
        newValue = "Retrofit";
      }
      if (order?.designType === "Retrofit") {
        newValue = "New build";
      }
      const res = await axios.put(
        `${apiBaseUrl}/api/v0/order/${order.id}`,
        { newOrderType: newValue },
        { headers: { "x-api-key": apiKey } }
      );
      set_order(res.data);
    } catch (err) {
      console.log("err", err);
    }
  }, [order.id, set_order, order.designType]);
  const ccMasterPhase = ["CC-PREREQUISITE", "CC-DESIGN" , "CC-HARDWARE" , "CC-FAT" , "CC-SAT" , "CC-DATA" ]
  
  const calculateDayToTimer = (order: orderType) => {
    if(!order?.timer) return null
    const date1 = new Date();
    const date2 = new Date(order?.timer ? order?.timer : "");
    const diffTime = (date2.getTime() - date1.getTime());
    return <React.Fragment>
              <label style={{ margin: "5px" }}>Action Timer:</label>
              <p style={{ fontSize: "16px", marginLeft: "10px", marginTop: "1px" }}>
                {Math.ceil(diffTime / (1000 * 60 * 60 * 24))} Days 
              </p>
            </React.Fragment> 
  }

  const panes = ccMasterPhase.map((phase) => { return (
    { menuItem: phase, render: () => <Tab.Pane>
      <div style={holder as React.CSSProperties}>
        { order?.todoList?.filter(todoItem => todoItem?.phase === phase)?.map((todo, index) => { 
          return (
            <Orderbox
              key={index}
              order={order}
              topic={todo?.topicName}
              edit={edit}
              set_order={set_order}
            />)
          })
      }
    </div>
    </Tab.Pane> }
  )}) 
  const oderContentsSubTitle = order?.orderContents?.map(oc => oc?.id).filter(function(item, pos, self) {
    return self.indexOf(item) === pos;
}).map(id => lookupProductNameOnId(id, abbreviations))

const changeTimer = useCallback(async (date: any) => {
  if(!localStorage.getItem("edit")) return null
  try {
    const res = await axios.put(
      `${apiBaseUrl}/api/v0/order/${order.id}`,
      { timer: date },
      { headers: { "x-api-key": apiKey } }
    );
    set_order(res.data);
  } catch (err) {
    console.log("err", err);
  }
}, [order.id, set_order]);

const showdate = order?.timer ? new Date(order?.timer) : null
  return (
    <div>
      <div
        style={{
          display: "flex",
          borderStyle: "solid",
          margin: "20px",
          marginTop: "5px",
          marginBottom: "5px",
          backgroundColor: order?.targetStatus === "cancelled" ? "#f57542" :"",
          opacity: order?.targetStatus === "cancelled" ? "0.6" :""
        }}
      >
        <div 
          style={{ width: "350px", display: "flex", flexDirection: "column", padding: "10px"}}>
            {/* This is the sidebar div */}
          {useShowOrEdit("siteName", order, set_order, 20, true)}
           {oderContentsSubTitle.length >= 1 ?  <ul>
           {oderContentsSubTitle?.map((p, i) => <li key={i}>{p}</li> )}
            </ul> : <p
              style={{ fontSize: "16px", marginLeft: "10px", marginTop: "1px" }}
            >
              No products added
            </p>}
          
          {useShowOrEdit("projectManager", order, set_order, 14, showDetails ? false : true, "Project Manager")}
          {useShowOrEdit("projectPOC", order, set_order, 14, showDetails ? false : true, "Project POC")}
          {useShowOrEdit("location", order, set_order, 14, showDetails ? false : true,  "Location")}
          {useShowOrEdit("country", order, set_order, 14, showDetails ? false : true,  "Country")}
          {useShowOrEdit("CoESalesId", order, set_order, 14, showDetails ? false : true, "Sales Order number")}
          {!showDetails ? <label style={{ margin: "5px" }}>Next action expected:</label> : null}
          {!showDetails ? <DatePicker selected={showdate} onChange={changeTimer} dateFormat="dd/MM/yyyy" /> : null}
          
          <label style={{ margin: "5px" }}>Last update:</label> 
          <p style={{ fontSize: "16px", marginLeft: "10px", marginTop: "1px" }}>
            {displayDate(order?.lastUpdate)}
          </p>
          
         
            {calculateDayToTimer(order)}            

          <label style={{ margin: "5px" }}>Workflow type:</label>
          {designTypeLabel(order?.designType, changeOrderType)}
          
          { !showDetails ?
          <p style={{ fontSize: "16px", marginLeft: "10px", marginTop: "1px" }}>
            <Icon name="shopping cart" />
            {countProducts(order)} total products
          </p> : null
          }
          { !showDetails? 
          <p style={{ fontSize: "16px", marginLeft: "10px", marginTop: "1px" }}>
            <Icon name="options" />
            {order?.orderContents?.length} different product types
          </p> : null }
          <p style={{ fontSize: "16px", marginLeft: "10px", marginTop: "1px" }}>
            <Icon name="comments" />
            {order?.comments?.length} comments{" "}
          </p>
          <p style={{ fontSize: "16px", marginLeft: "10px", marginTop: "1px" }}>
            <Icon name="percent" />
            {order?.orderCompletion}% completed ({order?.todoList?.length} tasks)
          </p>
          {showDetails ? (
            <a
              style={{ color: "black", marginLeft: "10px", marginTop: "1px" }}
              href={`/orders/${order?.id}`}
            >
              <Icon size="large" color="black" name="edit" />
              Details
            </a>
          ) : null}
        </div>
        <div style={{
          width: "100%",
          padding: "10px",
          overflow: "hidden"
        }}>
        <OrderStep order={order} />
          {!showDetails ?  <Tab panes={panes} /> : null }
          {useShowOrEdit("orderSummary",order,set_order,35,true,"Oder Summary")} 
        </div>
      </div>
    </div>
  );
}
