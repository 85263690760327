import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css'
import Fetcher from './pages/Fetcher';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css'
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import TagListPage from './pages/TagListPage';
import LocalTableListPage from './pages/LocalTableListPage';
import OrderTrackerPage from './pages/OrderTrackerPage';
import OrderTrackerEditPage from './pages/OrderTrackerEditPage';
import ApiKeyPage from './pages/ApiKeyPage';
import Home from './pages/Home';
import PrioritizedProducsPage from './pages/PrioritizedProducsPage';
import OrderTrackerActionsPage from './pages/OrderTrackerActionsPage';
import Navigation from './components/Navigation';
import ProductConnectionMethodPage from './pages/ProductConnectionMethodPage';
import DeviceDetails from './pages/DeviceDetails';
import DeviceList from './pages/DeviceList'
import OrderTrackerSSMPage from './pages/OrderTrackerSSMPage';
import OrderSSMDetailsPage from './pages/OrderSSMDetailsPage'
import LocalTableListDetails from './pages/LocalTableListDetails';
import DeviceListGeo from './pages/DeviceListGeo';

const router = createBrowserRouter([
  {
    path: "/",
    element:
    <React.Fragment>
    <Navigation/>
    <Home/>
    <ApiKeyPage/>
    </React.Fragment>
  },
  {
    path: "/fetcher",
    element: <React.Fragment>
      <Navigation/>
      <Fetcher/>
    </React.Fragment>
  },
  {
    path: "/authToken",
    element: 
    <React.Fragment>
<Navigation/>
<ApiKeyPage/>
</React.Fragment>
  },
  {
    path: "/taglist",
    element: 
    <React.Fragment>
      <Navigation/>
      <TagListPage/>
</React.Fragment>
  },
  {
    path: "/taglist/:deviceId",
    element: 
    <React.Fragment>
<Navigation/>
<TagListPage/>
</React.Fragment>
  },
  {
    path: "/localtaglist/:deviceId",
    element: 
    <React.Fragment>
      <Navigation/>
      <LocalTableListPage/>
    </React.Fragment>
  },
  {
    path: "/localtaglist/:deviceId/:table",
    element: 
    <React.Fragment>
      <Navigation/>
      <LocalTableListDetails/>
    </React.Fragment>
  },
  {
    path: "/orders",
    element:  <React.Fragment>
    <Navigation/>
    <OrderTrackerPage/>
    </React.Fragment>
  },
  {
    path: "/orders/:orderId",
    element: 
    <React.Fragment>
<Navigation/>
<OrderTrackerEditPage/>
</React.Fragment>
  },
  {
    path: "/prioritizedProducts",
    element: <React.Fragment>
    <Navigation/>
    <PrioritizedProducsPage/>
    </React.Fragment>
  },
  {
    path: "/productConnectionMethod",
    element: <React.Fragment>
    <Navigation/>
    <ProductConnectionMethodPage/>
    </React.Fragment>
  },
  {
    path: "/actions",
    element: <React.Fragment>
      <Navigation/>
      <OrderTrackerActionsPage/>
      </React.Fragment>
  },
  {
    path: "/ssm",
    element: <React.Fragment>
        <Navigation/>
        <OrderTrackerSSMPage/>
      </React.Fragment>
  },
  {
    path: "/ssm/:id",
    element: <React.Fragment>
        <Navigation/>
        <OrderSSMDetailsPage/>
      </React.Fragment>
  },
  {
    path: "/devices",
    element: <React.Fragment>
        <Navigation/>
        <DeviceList/>
      </React.Fragment>
  },
  {
    path: "/devicedetails/:id",
    element: <React.Fragment>
        <Navigation/>
        <DeviceDetails/>
      </React.Fragment>
  },
  {
    path: "/geospatial",
    element: <React.Fragment>
        <Navigation/>
        <DeviceListGeo/>
      </React.Fragment>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
     <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals(); //what does reportWebVitals do?
