import React, { useCallback } from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Table } from "semantic-ui-react";
//@ts-ignore
import { useParams } from "react-router-dom";
// import { deviceInfo as deviceInfoType } from "../types/deviceInfo";
import { apiBaseUrl } from "../config";
import { localTagTable } from "../types/tagInfo";


function LocalTableListPage() {
  // const [ deviceInfo, set_deviceInfo] = useState(null)
  // const [deviceInfo, set_deviceInfo] = useState<null | deviceInfoType>(null);
  // const [unitNum bnnnnnber, set_unitNumber] = useState<null | string | number>(2);
  // const [realTimeTagInfo, set_realTimeTagInfo] = useState<[]>();
  const [localTaglist, set_localTaglist] = useState<localTagTable[]>([]);

  const query = useParams();
  const getTaglistLocal = useCallback ( async () => {
    try {
      const url = `${apiBaseUrl}/api/v0/device/taglist/local/${query?.deviceId}`;
      const res = await axios.get(url);
      set_localTaglist(res.data);

      //dispatch({ type: 'SET_DATA', newData: res.data })
    } catch (err) {
      console.log("error", err);
    }
  },[query?.deviceId])
  // const getRTtagInfo = useCallback(async () => {
  //   try {
  //     const url = `${apiBaseUrl}/api/v0/device/taglist/realtime/${query?.deviceId}`;
  //     const res = await axios.get(url);
  //     set_realTimeTagInfo(res.data);
  //   } catch (err) {
  //     console.log("error", err);
  //   }
  // },[query?.deviceId])

  useEffect(() => {
    getTaglistLocal();
  }, [getTaglistLocal]);

  // if (data === null) return <p>Loading</p>;
  // console.log(data)
  return (
    <div className="App">
      <h1>CC-losant-agent-fetcher {query?.deviceId}</h1>

      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>TableName</Table.HeaderCell>
            <Table.HeaderCell>Cloud received</Table.HeaderCell>
            <Table.HeaderCell>month</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {localTaglist?.map((e: localTagTable, i: number) => (
            <Table.Row key={i}>
            <Table.Cell><a href={`/localtaglist/${query?.deviceId}/${e?.tableName}`} >{e?.tableName}</a></Table.Cell>
            <Table.Cell>{e?.received}</Table.Cell>
            <Table.Cell>{e?.month}</Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="7"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
}

export default LocalTableListPage;
