import React from 'react';
import EquipmentDailyOverview from '../components/EquipmentDailyOverview';
import EquipmentOperationalHistory from '../components/EquipmentOperationalHistory';
import EquipmentStatusHeader from '../components/EquipmentStatusHeader';
import EquipmentUseageStatsOverview from '../components/EquipmentUseageStatsOverview';
import Header from '../components/Header';
import TopSection from '../components/TopSection';
// import EquipmentConnectionOverview from '../components/EquipmentConnectionOverview';

function DeviceDetails() {
  return (
    <div className="App">
      <Header/>
      <TopSection/>
      <EquipmentStatusHeader/>
      <EquipmentOperationalHistory/>
      <EquipmentDailyOverview/>
      <EquipmentUseageStatsOverview/>
      {/* <EquipmentConnectionOverview/> */}

      {/* <EquipmentRecentReports/> */}
    </div>
  );
}

export default DeviceDetails;
