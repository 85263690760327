import axios from 'axios';
import React from 'react'
import { Checkbox, Icon, Table } from 'semantic-ui-react'
import { apiBaseUrl } from '../config';
import ingitionDataTypes from '../ingitionDataTypes.json';
import { parseTagPath } from '../parseTagpath';
import { tagInfo } from '../types/tagInfo';

interface tagProps{
    tagInfo: tagInfo,
    deviceId: string|undefined,
    newInfo: Function,
    unitNumber: number | string | null
}

export default function Tag({tagInfo,deviceId,newInfo, unitNumber}:tagProps) {
 const getDataType = (dt:number) => {
    for (var i=0; i < ingitionDataTypes.length; i++) {
        //@ts-ignore
        if (ingitionDataTypes[i].value === dt) {
            return `${ingitionDataTypes[i]['dataType']}`;
        }
    }
}

const fetchRealTimeToggle = async () => {
    const obj = {
        fetchRealTime: !tagInfo.fetchRealTime, 
        id:  tagInfo.id, 
        deviceId }
    //const res = 
    await axios.put(`${apiBaseUrl}/api/v0/device/taglist/realtime/${obj.deviceId}`, obj) 
    // newInfo({ type: 'SET_DATA', newData: res.data })
}


// const parseEpoch = (epochDate: number) =>{
//     if(!epochDate) return ``
//     var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
//     d.setUTCMilliseconds(epochDate);
//     return `${d.getUTCDate()}-${d.getUTCMonth()}-${d.getUTCFullYear()}`
// }

  return (
    <Table.Row>
        <Table.Cell>{tagInfo.id}</Table.Cell>
        <Table.Cell>{tagInfo.tagpath}</Table.Cell>
        <Table.Cell>{parseTagPath(tagInfo)}</Table.Cell>
        <Table.Cell>{ getDataType(tagInfo.datatype) }</Table.Cell>
        <Table.Cell>{ tagInfo?.defaultTag ? <Icon name='paper plane outline' color="blue" size="large" />  : null}</Table.Cell>
        <Table.Cell> <Checkbox onClick={fetchRealTimeToggle} toggle checked={tagInfo.fetchRealTime ? true : false}/></Table.Cell>
        {/* @ts-ignore */}
        {/* <Table.Cell>{tagInfo?.lastAttempt}</Table.Cell> */}
        {/* @ts-ignore */}
        {/* <Table.Cell>{parseEpoch(tagInfo?.lastFetch)}</Table.Cell> */}
        {/* @ts-ignore */}
        <Table.Cell>{tagInfo?.fetchCount }</Table.Cell>
        {/* <Table.Cell> <Checkbox toggle checked={tagInfo.fetchS3 ? true : false}/></Table.Cell> */}
        {/* <Table.Cell> <Checkbox toggle checked={tagInfo.fetchTimestream ? true : false}/></Table.Cell> */}
      </Table.Row>
  )
}
