import React, { useCallback, useEffect, useState } from "react";
import Order from "../components/Order";
import axios from "axios";
import {
  Button,
  Dimmer,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  Loader,
  Menu,
  Radio,
} from "semantic-ui-react";
import {apiResponseOrderStatsType, orderType} from '../types/order'
import { apiBaseUrl, apiKey } from "../config";
import { abbreviationType, dropdownProductOption } from "../types/order";
import { useNavigate } from "react-router-dom";
import OrderStats from "../components/OrderStats";

export default function OrderTrackerPage() {
  const [orderList, set_orderList] = useState<null | orderType[]>(null);
  const [orderStats, set_orderStats] = useState<null | apiResponseOrderStatsType>(null);
  const [searchProductGroup, set_searchProductGroup] = useState("");
  const [customerSearch, set_customerSearch] = useState("");
  const [pmFilterList, set_pmFilterList] = useState<any[]>([]);
  const [pmFilter, set_pmFilter] = useState<any[]>([]);
  const [showMenu, set_showMenu] = useState(false);
  const [productOptions, set_productOptions] = useState<
    dropdownProductOption[]
  >([]);
  type searchMethodType  = "percentage" | "name"| "lastUpdated" | "actionTimer"
  type sortingOrderType = "asc" | "desc"
  const [sortingMethod, set_sortingMethod] = useState<searchMethodType>("percentage")
  const [sortingOrder, set_sortingOrder] = useState<sortingOrderType>("asc")
  const navigate = useNavigate()

  const [abbreviations, set_abbreviations] = useState([])
    const getAbbreviations = useCallback(async () => {
        try {
          const res = await axios.get(`${apiBaseUrl}/api/v0/abbreviations`, {
            headers: { "x-api-key": apiKey },
          });
          set_abbreviations(res?.data);
        } catch (err) {
          console.log("err", err);
        }
      }, []);

      
  useEffect(() => {
    getAbbreviations();
  }, [getAbbreviations]);


  const resetFilters = () => {
    set_showMenu(false)
    set_searchProductGroup("")
    set_customerSearch("")
    set_pmFilter([])
  }
  const newOrder = useCallback( async ()=> {
    try {
      const res = await axios.post(`${apiBaseUrl}/api/v0/order`, {
        headers: { "x-api-key": apiKey },
      });
      console.log("res.", res.data.id)
      navigate(`/orders/${res.data.id}`)
    } catch (err) {
      console.log("err", err);
    }
  }, [navigate])

  const getData = useCallback(async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/api/v0/order`, {
        headers: { "x-api-key": apiKey },
      });
      set_orderList(res?.data?.orders);
      set_orderStats(res?.data?.stats);
    } catch (err) {
      console.log("err", err);
    }
  }, []);

  useEffect(()=> {
    if(orderStats){
      const filterlist = orderStats?.pmStats.map( (pm:any, index: number) => {
        return {key : index, value: pm?.name, text: pm?.name}
      })
    set_pmFilterList(filterlist)
    }
  }, [orderStats])
  
  useEffect(()=> {
    const storagePmFilter = localStorage.getItem("pmFilter")
    if(storagePmFilter){
      set_pmFilter(JSON.parse(storagePmFilter))
    }
  },[])

  useEffect(()=>{
    if( pmFilter?.length >= 1){
      localStorage.setItem("pmFilter", JSON.stringify(pmFilter))
    } 
  }, [pmFilter])

  const customers = [
    { key: "1", value: "boreal", text: "Boreal" },
    { key: "2", value: "fjord1", text: "Fjord1" },
    { key: "3", value: "abb", text: "ABB" },
    { key: "4", value: "", text: "All customers" },
  ];

  useEffect(() => {
    getData();
  }, [getData]);

  const menuClickHandler = (e: any, v: any) => {
    if (e?.target?.id !== "dropdownSearch") {
      set_showMenu(!showMenu);
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      set_showMenu(false);
    }
  };
  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const pmSelect = (a:any, b: any) => {
    set_pmFilter(b.value)
    if(b.value.length === 0 ){
      set_pmFilter([])
      localStorage.setItem("pmFilter", JSON.stringify([]))
    }
  }

  const oderList = (customerSearch: string, productType: string) => {
    let projectList: orderType[] = [];
    if (orderList) {
      if (!customerSearch && !productType && pmFilter.length === 0) {
        projectList = orderList; // no filter
      }
      else {
          projectList = orderList?.filter((order: orderType) => {
            if (customerSearch && order?.siteName?.toLowerCase()?.includes(customerSearch?.toLowerCase())) {
                return true;
            }
            for (let i = 0; i < order?.orderContents?.length; i++) {
                if (order?.orderContents?.[i].id === productType) {
                  return true;
                } 
            }
            if(pmFilter && pmFilter.includes(order?.projectManager)){
              return true
            }
            return false
          });
        }
    if (orderList === null) {
      return (
        <Dimmer active>
          {" "}
          <Loader />
        </Dimmer>
      );
    }
    const sortingByName = (a: orderType,b: orderType) => a?.siteName?.toLowerCase()?.localeCompare(b?.siteName?.toLowerCase())  
    const sortingByCompletion = (a: orderType,b: orderType) => b?.orderCompletion - a?.orderCompletion
    
    const sortingByLastUpdated = (a: orderType,b: orderType) => { 
      return b?.lastUpdate - a?.lastUpdate
    }
    const sortingByActionTimer = (a: orderType,b: orderType) => { 
      //@ts-ignore
      return new Date(b?.timer).getTime() - new Date(a?.timer).getTime()}
    
    
    const selectSortingFunction = (sortingMethod: searchMethodType) => {
      if(sortingMethod ===  "name") return  sortingByName
      if(sortingMethod ===  "percentage") return  sortingByCompletion
      if(sortingMethod ===  "lastUpdated") return  sortingByLastUpdated
      if(sortingMethod ===  "actionTimer") return  sortingByActionTimer
    }

    orderList.sort(selectSortingFunction(sortingMethod))
    if(sortingOrder === "desc")  orderList.reverse()
    return projectList?.map((c: orderType) => (
      <Order
        key={c.id}
        siteName={c.siteName}
        order={c}
        showDetails
        abbreviations={abbreviations}
      />
    ));
  }}
  const edit = localStorage.getItem("edit")
  useEffect(() => {
    //this code is 1-1 copied somewhere else
    const productOptions: dropdownProductOption[] = [];
    abbreviations?.forEach((abb: any) => {
      abb?.types?.forEach((type: abbreviationType) => {
        const option = {
          key: `${type?.id}`,
          value: `${type?.id}`,
          text: `${abb.fullProductName} (${type?.name})`,
        };
        productOptions.push(option);
      });
    });
    set_productOptions(productOptions);
  }, [abbreviations]);
  return (
    <div>
      <Grid.Row>
        <Grid.Column>
          <h1>OrderTracker ({orderList?.length} orders) </h1>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {edit ? <Grid.Column>
          <Menu floated="right">
            <Button.Group floated="right">
              <Button primary onClick={newOrder}>New order</Button>
            </Button.Group>
          </Menu>
        </Grid.Column> : null}
      </Grid.Row>
      <Menu>
        <Dropdown
          placeholder="Select Customer"
          labeled
          onClick={menuClickHandler}
          text={capitalizeFirstLetter(customerSearch)}
          fluid
          style={{ width: "300px" }}
          onChange={menuClickHandler}
        >
          <Dropdown.Menu open={showMenu}>
            <Input
              icon="search"
              iconPosition="left"
              id="dropdownSearch"
              value={customerSearch}
              className="search"
              onChange={(e, { value }) =>
                set_customerSearch(value?.toString() || "")
              }
              onKeyPress={handleKeyPress}
            />
            <Dropdown.Divider />
            {customers.map((option, i) => (
              <Dropdown.Item
                {...option}
                onClick={(e, { value }) =>
                  set_customerSearch(value?.toString() || "")
                }
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown
          placeholder="Select product group"
          fluid
          search
          selection
          options={productOptions}
          value={searchProductGroup}
          style={{ width: "300px" }}
          onChange={(e, { value }) => set_searchProductGroup(value?.toString() || "")}
        />
        <Dropdown placeholder='Select project managers' clearable search fluid multiple selection options={pmFilterList} onChange={pmSelect} value={pmFilter} />
        <p> <Icon name="remove" size="big" onClick={resetFilters}/> remove filters</p>
        </Menu>
        <Form inline>
          <Form.Group inline>
            <label>Sorting method </label>
            <Form.Field>
              <Radio
                label='% completed'
                name='SortingMethod'
                value='this'
                checked={sortingMethod === "percentage" ? true : false}
                onClick={()=> set_sortingMethod("percentage")}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='name'
                name='SortingMethod'
                checked={sortingMethod === "name" ? true : false}
                onClick={()=> set_sortingMethod("name")}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='action Timer'
                name='SortingMethod'
                checked={sortingMethod === "actionTimer" ? true : false}
                onClick={()=> set_sortingMethod("actionTimer")}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='last update'
                name='SortingMethod'
                checked={sortingMethod === "lastUpdated" ? true : false}
                onClick={()=> set_sortingMethod("lastUpdated")}
              />
            </Form.Field>

          <label>Sorting Direction </label>
            <Form.Field>
              <Radio
                label='asc'
                name='SortingDirection'
                checked={sortingOrder === "asc" ? true : false}
                onClick={()=> set_sortingOrder("asc")}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='desc'
                name='SortingDirection'
                checked={sortingOrder === "desc" ? true : false}
                onClick={()=> set_sortingOrder("desc")}
              />
            </Form.Field>
        </Form.Group>
      </Form>
     
      <OrderStats orderStats={orderStats}/>
      {oderList(customerSearch, searchProductGroup)}
    </div>
  );
}
