import React, { useCallback, useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { apiBaseUrl } from '../config';
import axios from 'axios';
import { deviceInfo } from '../types/device';

const containerStyle = {
  width: '100%',
  height: '90vw'
};


function DeviceListGeo() {
    const [info, set_info] = useState<deviceInfo>()

    const getInfo = useCallback( async (id:string) => {
        const url = `${apiBaseUrl}/api/v0/devices/info?deviceId=${id}`
        const result = await axios.get(url) 
        set_info(result?.data)
    },[])
    useEffect( () => {
          getInfo("63ee2b7c7f61c408e7057fff")
      }, [getInfo,])
      const OPTIONS = {
        minZoom: 4,
        maxZoom: 18,
      }   
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBdlZdHY3C5xdFLYCP7_6RWR0VR7kSduxo"
  })
   const center = {
    lat: 49.3270160168563, 
    lng: 10.241528651297632
  };
  
  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={5}
        options = {OPTIONS}
      >
        <Marker position={{ lat: 60.436562624620585, lng: 22.21920384410374}}       
        icon={ info?.connectionInfo?.connected  ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png" : "http://maps.google.com/mapfiles/ms/icons/red-dot.png"}/>
      </GoogleMap>
  ) : <></>
}

export default React.memo(DeviceListGeo)
 