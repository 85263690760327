import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { deviceInfo } from "../types/device";
import TopSectionMenu from "./TopSectionMenu";
import { apiBaseUrl } from "../config";

export default function TopSection() {
  const [info, set_info] = useState<deviceInfo>();
  const params = useParams();
  const getInfo = useCallback(async (id: string) => {
    const url = `${apiBaseUrl}/api/v0/devices/info?deviceId=${id}`;
    const result = await axios.get(url);
    set_info(result?.data);
  }, []);
  useEffect(() => {
    if (params?.id) {
      getInfo(params?.id);
    }
  }, [getInfo, params?.id]);
  return (
    <div className="topSection">
      {params?.id ? (
          <h1 className="topSectionUnitName">
            {info?.tags?.display_name}
            <sub className="topSectionUnitDesc">
              ({info?.tags?.display_type})
            </sub>
          </h1>
      ) : null}
      <ul style={{position: "absolute"}}>
      { params?.id ? (
          <li><a
            style={{ color: "black", fontWeight: "bold" }}
            target="_blank"
            rel="noreferrer"
            href={`https://app.losant.com/applications/6203d906b19bac31e713edbf/devices/${params?.id}/properties`}
          >
            Losant link
          </a></li>
      ) : null}
          <li><a
            style={{ color: "black", fontWeight: "bold" }}
            target="_blank"
            rel="noreferrer"
            href={`/taglist/${params?.id}`}
          >
        Taglist  Link </a></li>
      {params?.id && info?.tags?.display_IMO ? (
        <li><a
          style={{ color: "black", fontWeight: "bold"  }}
          target="_blank"
          rel="noreferrer"
          href={`https://www.vesselfinder.com/nl/?imo=${info?.tags?.display_IMO}`}
        >
          Vesselfinder
        </a></li>
      ) : null}
      {params?.id && info?.tags?.ncm_id ? (
        <li><a
          style={{ color: "black", fontWeight: "bold"  }}
          target="_blank"
          rel="noreferrer"
          href={`https://www.cradlepointecm.com/ecm.html#devices/dashboard?id=${info?.tags?.ncm_id}`}
        >
          Cradlepoint NCM link
        </a></li>
      ) : null}
      {params?.id && info?.tags?.ssmId ? (
        <li><a
          style={{ color: "black", fontWeight: "bold"  }}
          target="_blank"
          rel="noreferrer"
          href={`/ssm/${info?.tags?.ssmId}`}
        >
          SSM Info
        </a></li>
      ) : null}
      {params?.id && info?.tags?.tosibox_id ? (
        <li>
          Tosibox device ({info?.tags?.tosibox_id})
        </li>
      ) : null}
      </ul>
      <TopSectionMenu />
    </div>
  );
}
