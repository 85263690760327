import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Table } from "semantic-ui-react";
import { apiBaseUrl, apiKey } from "../config";
import { ssm } from "../types/ssm";

export default function OrderTrackerSSMPage() {
  const [ssm, set_ssm] = useState<null | ssm[]>(null);

  const getSSM = useCallback(async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/api/v0/ssm`, {
        headers: { "x-api-key": apiKey },
      });
      set_ssm(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  }, []);

  useEffect(() => {
    getSSM();
  }, [getSSM]);

  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Computername</Table.HeaderCell>
            <Table.HeaderCell>InstanceId</Table.HeaderCell>
            <Table.HeaderCell>LastSeen</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {ssm?.map((a, i: number) => {
            return (
              <React.Fragment key={i}>
                <Table.Row>
                  <Table.Cell>{a?.ComputerName}</Table.Cell>
                  <Table.Cell>
                    <a href={`/ssm/${a?.InstanceId}`}>{a?.InstanceId}</a>
                  </Table.Cell>
                  <Table.Cell>{a?.LastPingDateTime.toString()}</Table.Cell>
                  <Table.Cell>{a?.PingStatus}</Table.Cell>
                </Table.Row>
              </React.Fragment>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="4"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
}
