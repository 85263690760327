import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { Icon, Table } from "semantic-ui-react";
import { apiBaseUrl, apiKey } from "../config";
import deviceNames from "../deviceNames.json";
import "semantic-ui-css/semantic.min.css";

axios.defaults.headers.common["x-api-key"] = `${apiKey}`;

interface deviceStorageInfo {
  id: string;
  deviceInfoPath: string;
  taglistPath: string;
  taglistPathExists: boolean;
  deviceInfoPathExists: boolean;
}


function Fetcher() {
  const [fileList, set_fileList] = useState([]);

  const url = `${apiBaseUrl}/api/v0/devices/configurations`;
  const getData = useCallback(async () => {
    try {
      const res = await axios.get(url, { headers: { "x-api-key": apiKey } });
      set_fileList(res.data);
    } catch (err) {
      console.log("error", err);
    }
  }, [url]);


  const link = (f: deviceStorageInfo) => {
    const device = deviceNames.filter((d) => d.id === f.id);

    // const id =  path.replace("losant/","").replace("/taglist.json","")
    return (
      <Table.Row key={f.id}>
        <Table.Cell>{f.id}</Table.Cell>

        <Table.Cell>{device?.[0]?.name}</Table.Cell>
        <Table.Cell>{device?.[0]?.configured ? "yes" : ""}</Table.Cell>

        <Table.Cell>
          <a href={`/taglist/${f.id}`}>link</a>
        </Table.Cell>
        {/* <Table.Cell>{f.taglistPathExists? "yes": "no"}</Table.Cell>  */}
        <Table.Cell>
          {f.taglistPathExists ? (
            <Icon name="check" circle />
          ) : (
            <Icon color="red" name="exclamation" circle />
          )}
        </Table.Cell>
        <Table.Cell>
          {f.deviceInfoPathExists ? (
            <Icon name="check" circle />
          ) : (
            <Icon color="red" name="exclamation" circle />
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <div className="App">
      <h1>CC-losant-agent-fetcher home</h1>
      {fileList.length >= 1 ? <p>Count: {fileList.length}</p> : null}
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Name </Table.HeaderCell>
            <Table.HeaderCell>Configured </Table.HeaderCell>
            <Table.HeaderCell>Link </Table.HeaderCell>
            <Table.HeaderCell>taglistPathExists </Table.HeaderCell>
            <Table.HeaderCell>deviceInfoPathExists </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{fileList.map((path) => link(path))}</Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="6"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
}

export default Fetcher;
