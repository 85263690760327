import { deviceInfo } from "./types/deviceInfo";

export const determineUnitNumber = (info: deviceInfo) => {
    // const str = `POS-b6-u4`;
    let m;
    const regex = /-u([0-9])/gm;

    while ((m = regex.exec(info.deviceName)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      return(m?.[1]);
    }
return null;
  };