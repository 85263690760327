import React, { MouseEvent, useState } from 'react'

interface equipmentStatsProps {

    title: string
    value: number 
    unit?: "h" | "%" | ""
    leaf?: boolean
    updated: string
    description: string
}

export default function EquipmentStats({title,value,unit,leaf, updated, description}:equipmentStatsProps) {
  const [updatedVisible , set_updatedVisible] = useState(false)
  const [descriptionVisible , set_descriptionVisible] = useState(false)
  const handleMouseMove = (a:MouseEvent) => {
    if(a?.type === "mouseleave"  ){
      set_descriptionVisible(false)
      set_updatedVisible(false)
    }else{
      set_updatedVisible(true)
    }
  }
  const handleClick = () => {
    set_descriptionVisible(!descriptionVisible)
  }
  return (
    <div  className='equipmentStatsBox' 
          onMouseEnter={handleMouseMove} 
          onMouseLeave={handleMouseMove}
          onClick={handleClick}
          >
        <p className='equipmentStatsText'>{title}</p>
        <p className='equipmentStatsValue'>{value}<span className='equipmentStatsValueUnit'>{unit}</span></p>
        <p className='equipmentStatsUpdated' style={{visibility: updatedVisible? "visible" : "hidden"}} >Updated:{updated}</p>
        { leaf ?  <svg className="equipmentStatsDecorator" width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.10821 8.429C2.27158 10.9388 0.674412 14.3404 0.666291 17.8894C0.65817 21.4384 2.23975 24.8458 5.06488 27.3657C8.24988 20.849 13.9265 15.4057 21.0115 12.1665C14.8963 16.7438 10.7682 23.0581 9.33321 30.0298C14.9665 32.3873 21.8999 31.5248 26.5582 27.404C34.0982 20.734 35.3332 0.666504 35.3332 0.666504C35.3332 0.666504 12.6482 1.759 5.10821 8.429Z" fill="#5AF569"/>
                  </svg>
        : null}
        <div className="tooltip" style={{visibility: descriptionVisible? "visible" : "hidden"}} >
          <span className="tooltiptext">{description}</span>
        </div>
    </div>
  )
}
