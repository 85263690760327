import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// import data1 from '../dummyData/operationalStates.json'
import { parseTimeDate } from '../parseDate'
import EquipmentStatusIcon, { equipmentIconType } from './EquipmentStatusIcon'
import { apiBaseUrl } from '../config'

export interface operationalState {
    icon: equipmentIconType
    stateName: string
    info: string
    stateStart: string //TODO: | Number
    stateEnd: string | Number
    stateDuration: string
    
}
export interface operationStateSummary{
    date: string
    chargingCyles: string | number
    chargingTime: string
    missedConnections: string | number
    abortedConnections: string | number
    uptime: string
    downtime: string
    alerts: string
}
export default function EquipmentOperationalHistory() {
    const [limit, set_limit] = useState(5)
    const [data, set_data] = useState<operationalState[]>([])
    const params = useParams()
    const getData = useCallback( async (id:string) => {
        const url = `${apiBaseUrl}/api/v0/devices/operation/states?deviceId=${id}&limit=${limit}`
        const result = await axios.get(url)    
        set_data(result?.data)
    },[limit])
    useEffect( () => {
        if(params?.id) 
            getData(params?.id)
    }, [params?.id,getData,limit])
    
  return (
    <div className="equipmentOperationalHistorySection">
        <table>
            <thead>
                <th></th>
                <th style={{textAlign: "left", width: "300px"}}>Status</th>
                <th style={{textAlign: "left", width: "300px"}}>Time started</th>
                <th style={{textAlign: "left", width: "300px"}}>Duration</th>
            </thead>
            <tbody>
                {data.map( (r,i) => {
                    const icon:equipmentIconType = r.icon
                return ( <tr key={i}>
                            <td><EquipmentStatusIcon iconType={icon || "gray"} size="small"/></td>
                            <td>{r.stateName}</td>  
                            <td>{parseTimeDate(r.stateStart)}</td>  
                            <td>{r?.stateDuration ? `${r?.stateDuration} min` : null}</td>  
                        </tr>
                        )
                })}
            </tbody>
        </table>
        <hr/>
        <p className='showMoreButton'
        onClick={()=> set_limit(limit + 50)}
        
        >{"Show +50 lines  history >"}</p>
    </div>
  )
}
