import { tagInfo } from "./types/tagInfo"

export const parseTagPath = (info:tagInfo) => {
    //this function is copied from a workflow called MasterDbHarvester and modified for use here
	  const item = {...info}
    const fwdSlash = item.tagpath.split("/").join("_")
		const spaces = fwdSlash.split(" ").join("_")
    const lowerCase = spaces.toLowerCase()
    const dash = lowerCase.split("-").join("_")
    const noVersion = dash.replace("v1804203_", "")
    const unitNumber = noVersion
    .replace("u"+ 1 + "_", 'ux_') // this is modified compared to the workflow
    .replace("u"+ 2 + "_", 'ux_') // this is modified compared to the workflow
    .replace("u"+ 3 + "_", 'ux_') // this is modified compared to the workflow
    .replace("u"+ 4 + "_", 'ux_') // this is modified compared to the workflow
    .replace("u"+ 5 + "_", 'ux_') // this is modified compared to the workflow
    .replace("u"+ 6 + "_", 'ux_') // this is modified compared to the workflow
    .replace("u"+ 7 + "_", 'ux_') // this is modified compared to the workflow
    .replace("u"+ 8 + "_", 'ux_') // this is modified compared to the workflow

    item.tagpath = unitNumber.split("__").join("_")
    return item.tagpath  // this is modified compared to the workflow
}