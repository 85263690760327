import { useEffect } from 'react'
import { apiKey } from "../config";
import { useNavigate } from 'react-router-dom';

export default function CheckToken() {
  const navigate = useNavigate()

  useEffect(()=>{
    if(apiKey === null){
      navigate("/authToken")
    }
  },[navigate])
  return null
}
