
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dimmer, Loader } from 'semantic-ui-react'

export default function Home() {
    const navigate = useNavigate()
    
    useEffect(()=> {
        const apiKey = localStorage.getItem("apiKey")
        if(apiKey) navigate("/orders")    
    }, [navigate] )
  return (
      <Dimmer active>
        <Loader>Loading cc</Loader>
      </Dimmer>

  )
}
