import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { Icon, Table } from 'semantic-ui-react';
import { apiBaseUrl, apiKey } from '../config';
import { productGroupType } from '../types/abbreviationsTypes';

export default function ProductConnectionMethodPage() {
    const [abbreviations, set_abbreviations] = useState([])
    const getAbbreviations = useCallback(async () => {
        try {
          const res = await axios.get(`${apiBaseUrl}/api/v0/abbreviations`, {
            headers: { "x-api-key": apiKey },
          });
          set_abbreviations(res?.data);
        } catch (err) {
          console.log("err", err);
        }
      }, []);


  useEffect(() => {
    getAbbreviations();
  }, [getAbbreviations]);


  return (
    <div>
      <h2>Cavotec Connect connection and storage info</h2>
      <p>Data connection and storage options that we have</p>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan='2'>Product group</Table.HeaderCell>
            <Table.HeaderCell rowSpan='2'>Abbreviation</Table.HeaderCell>
            <Table.HeaderCell colSpan='2' textAlign='center'>Type</Table.HeaderCell>
            <Table.HeaderCell colSpan='2' textAlign='center'>Data gathering options</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Full name</Table.HeaderCell>
            <Table.HeaderCell>CC-target</Table.HeaderCell>
            <Table.HeaderCell>Data interface options</Table.HeaderCell>
            <Table.HeaderCell>Data storage options</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            abbreviations?.map((a:productGroupType, i:number) => {
            return a?.types?.map((b,y) =>{
                return (<React.Fragment>
                            <Table.Row key={i}>
                                {y === 0 ? <Table.Cell  rowSpan={a?.types?.length} >{a?.fullProductName}</Table.Cell> : null}
                                {y === 0 ? <Table.Cell rowSpan={a?.types?.length} >{a?.abbreviation}</Table.Cell> : null}
                                <Table.Cell>{`${a.fullProductName} (${a?.types?.[y]?.name})`}</Table.Cell>
                                <Table.Cell>{b?.ccTarget2023? <Icon color='green' name='checkmark' size='large'/>: ""}</Table.Cell>
                                <Table.Cell>{b?.connectionOptions?.join(",")} </Table.Cell>
                                <Table.Cell>{b?.storageOptions?.join(",")} </Table.Cell>
                            </Table.Row>
                        </React.Fragment>
                        );
            })
        })
        }
    </Table.Body>
            
    <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="6"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  )
}
