import React from 'react'
import { useNavigate } from 'react-router-dom'
import { pageList } from '../types/pageList'
interface companyLogoProps{
  link: pageList
}

export default function CompanyLogo({link}:companyLogoProps) {
  const navigate = useNavigate()
  const click = () => {
    navigate(`/${link}`)
  }

  return (
    <img alt="logo" className='companyHeaderLogo' src='/cavotecLogo.png' onClick={click}/>
  )
}
