import axios from "axios";
import React, { useCallback, useState } from "react";
import { Button, Input, Table } from "semantic-ui-react";
import { apiBaseUrl, apiKey } from "../config";
import { displayDate } from "../displayDate";
import { orderType } from "../types/order";
interface OrderCommentsProps {
    order: orderType;
    set_order: any
  }

export default function OrderComments({ order,set_order }: OrderCommentsProps) {
  const [comment, set_comment] = useState("")
  const d = new Date()
  d.setHours(12)
  const date = d.getTime()
  const edit = localStorage.getItem("edit");
  
  const addComment  = useCallback( async () => {
    try{
        if(comment && edit){
            const add = {comment, by: edit, date} 
            const res= await axios.post(`${apiBaseUrl}/api/v0/order/${order.id}/comment`, add ,{  headers: { 'x-api-key': apiKey}})
            set_order(res.data)
            set_comment("")
        }
        
    }catch(err){
      console.log("err",err)
    }
  },[set_order, order.id, comment, edit,date])


  return (
    <div>
      <h2>Comments</h2>
      <p>Place comments here to describe the history of this project</p>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>By</Table.HeaderCell>
            <Table.HeaderCell>Comment</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {order?.comments?.map((oc, i) => {
            const d = new Date(oc?.date)
            return (
              <Table.Row key={i}>
                <Table.Cell>{displayDate(d)}</Table.Cell>
                <Table.Cell>{oc?.by}</Table.Cell>
                <Table.Cell>{oc?.comment}</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            );
          })}
        { edit ? 
          <Table.Row>
            <Table.Cell width={1}>
              {displayDate(date)}
              {/* <Input value={date} onChange={(e) => set_date(e?.target?.value)} style={{width: "100%"}}/> */}
              </Table.Cell>
            <Table.Cell width={1}>
             {edit}
            </Table.Cell>
            <Table.Cell>
              <Input value={comment} onChange={(e) => set_comment(e?.target?.value)} style={{width: "100%"}}/>
            </Table.Cell>
            <Table.Cell width={2}>
                <Button primary onClick={addComment}>Add</Button>
            </Table.Cell>
          </Table.Row>
          : null }
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="4"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
}
