import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Table} from "semantic-ui-react";
import { orderType} from '../types/order'
import { apiBaseUrl, apiKey } from "../config";
import { displayDate } from "../displayDate";

export default function OrderTrackerActionsPage() {
  const [orderList, set_orderList] = useState<null | orderType[]>(null);

  const getData = useCallback(async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/api/v0/order`, {
        headers: { "x-api-key": apiKey },
      });
      set_orderList(res?.data?.orders);
    } catch (err) {
      console.log("err", err);
    }
  }, []);
  
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div>

  <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan='2'>Site name</Table.HeaderCell>
            <Table.HeaderCell rowSpan='2'>Date recorded</Table.HeaderCell>
            <Table.HeaderCell colSpan='2' textAlign="center" >Actions</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Action holder</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            orderList?.map((a, i:number) => {
            return a?.actions?.filter((action) => !action?.completed).map((b,y) =>{
                const rowSpan = a?.actions?.filter((action) => !action?.completed)?.length
                return (<React.Fragment  key={i+y} >
                            <Table.Row>
                                {y === 0 ? <Table.Cell  rowSpan={rowSpan} >
                                  <a href={`/orders/${a?.id}`}>{a?.siteName}</a></Table.Cell> : null}
                                {y === 0 ? <Table.Cell rowSpan={rowSpan} >{displayDate(a?.lastUpdate)}</Table.Cell> : null}
                                <Table.Cell>{b?.owner}</Table.Cell>
                                <Table.Cell>{b?.action}</Table.Cell>
                            </Table.Row>
                        </React.Fragment>
                        );
            })
        })
        }
    </Table.Body>
    <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="4"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
}
