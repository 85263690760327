import React, { useState } from 'react'
import { Button,  Form } from 'semantic-ui-react'
import { useNavigate } from "react-router-dom";


export default function ApiKeyPage() {
  const [newKey, set_newKey] = useState(localStorage.getItem("apiKey"))
  const [apiKey, set_apiKey] =  useState(localStorage.getItem("apiKey"))
  const navigate = useNavigate();

 const saveKey = () => {
   if(newKey) {
     localStorage.setItem("apiKey", newKey)
     set_apiKey(newKey)
     navigate(0)
   }
   
  }
  const deleteKey = () => {
    localStorage.removeItem("apiKey")
    set_apiKey("")
    set_newKey("")
 }
 const goHome = () => {
  navigate("/")
 }

  return (
    <div style={{display: "flex", alignItems: "center", flexDirection: "column", marginTop: "200px"}}>
      <h1>Check your authentication key: </h1>
       <div>

       {<Form style={{marginTop: "50px",width: "600px"}}>
                    <Form.Field>
                      <label>Api Key</label>
                      <input 
                          placeholder='Enter your key here'
                          onChange={ (e)=> set_newKey(e?.target?.value)} 
                          value={newKey || ""} 
                          />
                    </Form.Field>
                    <Button.Group floated="left">
                          {apiKey? <Button type='submit' onClick={deleteKey}>Delete key</Button> : null}
                    </Button.Group>
                    <Button.Group floated="right">
                          {newKey && apiKey !== newKey? <Button type='submit' onClick={saveKey} primary={apiKey !== newKey}>Submit key</Button> : null }
                          {apiKey && apiKey === newKey? <Button content='Go home' onClick={goHome}primary={apiKey === newKey} /> : null}
                    </Button.Group>
                  
                    
                    
                  </Form>
      }
        </div>
    </div>
  )
}
