export const parseDate = (input: any) : string => {
    if(typeof(input) ===  "string"){
        const d = new Date(input)
        return `${d.getDate().toString().padStart(2, '0')}-${(d.getMonth() + 1).toString().padStart(2, '0') }-${d.getFullYear()}`
    }
    if(typeof(input) ===  "undefined"){
        console.log("todo write parser, info missing",input)
        return ""
    }
    else{
        console.log("todo write parser",input)
        return "todo, write parser"
    }
}
export const parseTimeDate = (input: any) : string => {
    if(typeof(input) ===  "string"){
        const d = new Date(input)
        return `${d.getDate().toString().padStart(2, '0')}-${(d.getMonth() + 1).toString().padStart(2, '0') }-${d.getFullYear()}${" "}${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`
    }
    if(typeof(input) ===  "number"){
        const d = new Date(0)
        if(input.toString().length >= 13){
            d.setUTCSeconds(input/1000);
        }else{
            d.setUTCSeconds(input);
        }
        return `${d.getDate().toString().padStart(2, '0')}-${(d.getMonth() + 1).toString().padStart(2, '0') }-${d.getFullYear()}${" "}${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`
    }
    if(typeof(input) ===  "undefined"){
        console.log("todo write parser, info missing",input)
        return ""
    }
    else{
        console.log("todo write parser",input)
        return "todo, write parser"
    }
}

export const timeSince = (input: string | null ) => {
    if(!input) return null
    const d = new Date(input)
    const now = new Date()
    const secondsInDay = 24 * 3600
    const timediff =  now.getTime() - d.getTime()
    const timediffSeconds =  (timediff / 1000)
    const timediffDays =  Math.floor(timediffSeconds / secondsInDay)
    const timediffHours = Math.floor((timediffSeconds - (timediffDays * 3600 * 24)) / 3600)
    const timediffMins = Math.floor((timediffSeconds - (timediffHours * 3600) - (timediffDays * secondsInDay)) % 60)
    return `${timediffDays}D  ${timediffHours}H ${timediffMins}M`
}


