import React from "react";

export type equipmentIconType =
  | "green"
  | "red"
  | "blue"
  | "yellow"
  | "gray"
  | "charging"
  | "cross"
  | "check";
interface equipmentStatusIconProps {
  iconType: equipmentIconType;
  size?: "small";
}

export default function EquipmentStatusIcon({
  iconType,
  size,
}: equipmentStatusIconProps) {
  const svgSize = size === "small" ? 30 : 56;
  const dotSize = size === "small" ? 15 : 28;
  const chargeIconSize = size === "small" ? 160 : 300;

  const dotwrapper = (circle: any) => {
    return (
      <svg
        className="equipmentStatusIcon"
        width={svgSize}
        height={svgSize}
        viewBox={`0 0 ${svgSize} ${svgSize}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        {circle}
      </svg>
    );
  };

  return (
    <div>
      {iconType === "green"
        ? dotwrapper(
            <circle cx={dotSize} cy={dotSize} r={dotSize} fill="#5AF569" />
          )
        : null}
      {iconType === "yellow"
        ? dotwrapper(
            <circle cx={dotSize} cy={dotSize} r={dotSize} fill="#FFD260" />
          )
        : null}
      {iconType === "red"
        ? dotwrapper(
            <circle cx={dotSize} cy={dotSize} r={dotSize} fill="#F55A5A" />
          )
        : null}
      {iconType === "blue"
        ? dotwrapper(
            <circle cx={dotSize} cy={dotSize} r={dotSize} fill="#0091D3" />
          )
        : null}
      {iconType === "gray"
        ? dotwrapper(
            <circle cx={dotSize} cy={dotSize} r={dotSize} fill="#999999" />
          )
        : null}
      {iconType === "cross" ? (
        <svg
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 122.88 122.88"
          width="30"
          height="30"
        >
          <path
            fill="#ff4141"
            d="M6,6H6a20.53,20.53,0,0,1,29,0l26.5,26.49L87.93,6a20.54,20.54,0,0,1,29,0h0a20.53,20.53,0,0,1,0,29L90.41,61.44,116.9,87.93a20.54,20.54,0,0,1,0,29h0a20.54,20.54,0,0,1-29,0L61.44,90.41,35,116.9a20.54,20.54,0,0,1-29,0H6a20.54,20.54,0,0,1,0-29L32.47,61.44,6,34.94A20.53,20.53,0,0,1,6,6Z"
          />
        </svg>
      ) : null}
      {iconType === "check" ? (
        <svg
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 122.88 102.97"
          width="30"
          height="30"
        >
          <path
            fill="#10a64a"
            d="M4.82,69.68c-14.89-16,8-39.87,24.52-24.76,5.83,5.32,12.22,11,18.11,16.27L92.81,5.46c15.79-16.33,40.72,7.65,25.13,24.07l-57,68A17.49,17.49,0,0,1,48.26,103a16.94,16.94,0,0,1-11.58-4.39c-9.74-9.1-21.74-20.32-31.86-28.9Z"
          />
        </svg>
      ) : null}

      {iconType === "charging" ? (
        <React.Fragment>
          {dotwrapper(
            <React.Fragment>
              <circle cx={dotSize} cy={dotSize} r={dotSize} fill="#0091D3" />,
              <svg
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-7 -5 132.88 132.88"
                width={chargeIconSize}
                height={chargeIconSize}
              >
                <path
                  d="M9.25096 0.0593982C9.34883 0.109044 9.42456 0.186265 9.4658 0.278444C9.50703 0.370623 9.51131 0.472312 9.47795 0.566898L7.67696 5.6874H11C11.0976 5.68736 11.1931 5.71235 11.2747 5.75928C11.3564 5.80621 11.4205 5.87302 11.4592 5.95147C11.4979 6.02991 11.5095 6.11656 11.4926 6.20072C11.4757 6.28487 11.4309 6.36285 11.364 6.42502L3.36396 13.8625C3.28655 13.9345 3.18354 13.9815 3.07173 13.9957C2.95991 14.01 2.84588 13.9907 2.74823 13.9409C2.65057 13.8912 2.57504 13.814 2.53395 13.7219C2.49286 13.6298 2.48863 13.5282 2.52196 13.4338L4.32296 8.3124H0.999955C0.9023 8.31243 0.806771 8.28744 0.725161 8.24052C0.643551 8.19359 0.579432 8.12678 0.54072 8.04833C0.502008 7.96988 0.490398 7.88323 0.507322 7.79908C0.524246 7.71492 0.568964 7.63694 0.635955 7.57477L8.63595 0.137273C8.71327 0.0653475 8.81611 0.0184208 8.92777 0.00412322C9.03943 -0.0101743 9.15333 0.00899813 9.25096 0.0585232V0.0593982Z"
                  fill="white"
                />
              </svg>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : null}
      {/* </svg> */}
    </div>
  );
}
