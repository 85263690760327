import React from "react";
import { Icon, Step } from "semantic-ui-react";
import { orderType } from "../types/order";

interface orderStepProps {
  order: orderType;
}

export default function OrderStep({order}: orderStepProps) {
  const prerequisites = order?.todoList?.filter(todo => todo.phase === "CC-PREREQUISITE")?.length
  const prerequisitesCompleted = order?.todoList?.filter(todo => todo.phase === "CC-PREREQUISITE" && todo?.completed)?.length
  
  const design = order?.todoList?.filter(todo => todo.phase === "CC-DESIGN")?.length
  const designComleted = order?.todoList?.filter(todo => todo.phase === "CC-DESIGN" && todo?.completed)?.length
  
  const hardware = order?.todoList?.filter(todo => todo.phase === "CC-HARDWARE")?.length
  const hardwareComleted = order?.todoList?.filter(todo => todo.phase === "CC-HARDWARE" && todo?.completed)?.length
  
  const fat = order?.todoList?.filter(todo => todo.phase === "CC-FAT")?.length
  const fatCompleted = order?.todoList?.filter(todo => todo.phase === "CC-FAT" && todo?.completed)?.length
  
  const sat = order?.todoList?.filter(todo => todo.phase === "CC-SAT")?.length
  const satCompleted = order?.todoList?.filter(todo => todo.phase === "CC-SAT" && todo?.completed)?.length

  const data = order?.todoList?.filter(todo => todo.phase === "CC-DATA")?.length
  const dataCompleted = order?.todoList?.filter(todo => todo.phase === "CC-DATA" && todo?.completed)?.length

  return (
    <div style={{width: "100%", borderStyle:"solid"}}>
      <Step.Group size="mini" stackable='tablet'>
        <Step completed={prerequisites ===  prerequisitesCompleted ? true : false}> 
          <Icon name="play" />
          <Step.Content>
            <Step.Title>CC-Prerequisites</Step.Title>
            <Step.Description>Road to success</Step.Description>
          </Step.Content>
        </Step>
        <Step completed={design ===  designComleted ? true : false}> 
          <Icon name="chart pie" />
          <Step.Content>
            <Step.Title>CC-Design</Step.Title>
            <Step.Description>CC is part of BOM</Step.Description>
          </Step.Content>
        </Step>

        {/* disabled is also a state */}
        <Step completed={hardware ===  hardwareComleted ? true : false}> 
          <Icon name="server" />
          <Step.Content>
            <Step.Title>CC-Hardware</Step.Title>
            <Step.Description>Source the hardware</Step.Description>
          </Step.Content>
        </Step>
        <Step completed={fat ===  fatCompleted ? true : false}> 
          <Icon name="factory" />
          <Step.Content>
            <Step.Title>CC-FAT</Step.Title>
            <Step.Description>Cavotec Connect FAT done</Step.Description>
          </Step.Content>
        </Step>

        <Step completed={sat ===  satCompleted ? true : false}> 
          <Icon name="location arrow" />
          <Step.Content>
            <Step.Title>CC-SAT</Step.Title>
            <Step.Description>Cavotec Connect SAT done</Step.Description>
          </Step.Content>
        </Step>
        <Step completed={data ===  dataCompleted ? true : false}> 
          <Icon name="dashboard" />
          <Step.Content>
            <Step.Title>CC-Data-Flow</Step.Title>
            <Step.Description>Real-time dashboarding active</Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    </div>
  );
}
