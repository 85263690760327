import React from 'react';
import EquipmentList from '../components/EquipmentList';
import Header from '../components/Header';
import TopSection from '../components/TopSection';

function DeviceList() {
  return (
    <div className="App">
      <Header/>
      <TopSection/>
      <EquipmentList/>
    </div>
  );
}

export default DeviceList;
