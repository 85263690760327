import { ProductGroupListType } from "../types/abbreviationsTypes";

export const idLookup = (id: string, abbreviations: ProductGroupListType) =>{
    for(let i = 0; i < abbreviations?.length ; i++){
        for(let y = 0 ; y <= abbreviations[i]?.types?.length; y++ ){
            if(abbreviations?.[i]?.types?.[y]?.id === id){
                return abbreviations?.[i]?.types?.[y]    
            }
        }    
    }
    return null
} 
export const idLookupProductGroup = (id: string, abbreviations: ProductGroupListType) =>{
    for(let i = 0; i < abbreviations.length ; i++){
    for(let y = 0 ; y <= abbreviations[i]?.types?.length; y++ ){
        if(abbreviations?.[i]?.types?.[y]?.id === id){
            return abbreviations?.[i]    
        }
    }    
    }
    return null
} 

export const lookupProductNameOnId = (id: string, abbreviations: ProductGroupListType) => {
    return `${idLookupProductGroup(id,abbreviations)?.fullProductName} (${idLookup(id,abbreviations)?.name})`
}