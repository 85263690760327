import React, { useCallback } from "react";
import axios from "axios";
import { useState, useEffect, useReducer } from "react";
import Tag from "../components/Tag";
import { Table } from "semantic-ui-react";
//@ts-ignore
import { useParams } from "react-router-dom";
import { tagInfo } from "../types/tagInfo";
import { deviceInfo as deviceInfoType } from "../types/deviceInfo";
import { apiBaseUrl } from "../config";
import DeviceInfo from "../components/DeviceInfo";
import standardTags from "../defaultTag.json";
import { determineUnitNumber } from "../determineUnitNumber";
import { parseTagPath } from "../parseTagpath";

//@ts-ignore
function exampleReducer(state, action) {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        data: action?.newData?.sort(
          (a: any, b: any) => b.defaultTag - a.defaultTag
        ),
      };
    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          data: state?.data?.slice()?.reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        };
      }
      console.log("column", action.column);
      if (action.column === "fetchRealTime") {
        const sorted = state.data.sort(
          (a: any, b: any) => b.fetchRealTime - a.fetchRealTime
        );
        const newState = {
          ...state,
          column: action.column,
          data: sorted,
          direction: "ascending",
        };
        return newState;
      }
      if (action.column === "defaultTag") {
        const sorted = state.data.sort(
          (a: any, b: any) => b.defaultTag - a.defaultTag
        );
        const newState = {
          ...state,
          column: action.column,
          data: sorted,
          direction: "ascending",
        };
        return newState;
      }

      return {
        column: action.column,
        data: state.data,
        direction: "ascending",
      };
    default:
      throw new Error();
  }
}

function TagListPage() {
  // const [ deviceInfo, set_deviceInfo] = useState(null)
  const [deviceInfo, set_deviceInfo] = useState<null | deviceInfoType>(null);
  const [unitNumber, set_unitNumber] = useState<null | string | number>(2);
  const [realTimeTagInfo, set_realTimeTagInfo] = useState<[]>();
  const [json, set_json] = useState([]);

  const query = useParams();

  const [state, dispatch] = useReducer(exampleReducer, {
    column: "defaultTag",
    data: null,
    direction: "descending",
  });

  const { column, data, direction } = state;

  const getTaglist = useCallback ( async () => {
    try {
      const url = `${apiBaseUrl}/api/v0/device/config/${query?.deviceId}`;
      const res = await axios.get(url);
      set_json(res.data);

      //dispatch({ type: 'SET_DATA', newData: res.data })
    } catch (err) {
      console.log("error", err);
    }
  },[query?.deviceId])
  const getDeviceInfo = useCallback ( async () => {
    try {
      const url = `${apiBaseUrl}/api/v0/device/info/${query?.deviceId}`;
      const res = await axios.get(url);
      set_deviceInfo(res.data);
      set_unitNumber(determineUnitNumber(res.data));
    } catch (err) {
      console.log("error", err);
    }
  },[query?.deviceId])
  const getRTtagInfo = useCallback(async () => {
    try {
      const url = `${apiBaseUrl}/api/v0/device/taglist/realtime/${query?.deviceId}`;
      const res = await axios.get(url);
      set_realTimeTagInfo(res.data);
    } catch (err) {
      console.log("error", err);
    }
  },[query?.deviceId])

  const fetchStatusTag = useCallback((tagInfo: tagInfo) => {
    //@ts-ignore
    const isFetched = realTimeTagInfo?.filter((i) => i?.id === tagInfo?.id);
    if (isFetched && isFetched?.length >= 1) {
      const fetchInfo = isFetched[0];
      //@ts-ignore
      const cpy = { ...tagInfo, ...fetchInfo };
      return cpy;
    }
    return tagInfo;
  }, [realTimeTagInfo]);

  //needs to be done on aws.. makese no sense here!
  const addDefaultTag = useCallback(
    (data: tagInfo[]) => {
      return data?.map((t) => defaultTag(t, Number(unitNumber)));
    },
    [unitNumber]
  );
  const addFetchStatus = useCallback(
    (data: tagInfo[]) => {
      return data?.map((t) => fetchStatusTag(t));
    },
    [fetchStatusTag]
  );

  useEffect(() => {
    const added = addDefaultTag(json);
    const rffsadded = addFetchStatus(added);
    dispatch({ type: "SET_DATA", newData: rffsadded });
  }, [unitNumber, json, addDefaultTag, addFetchStatus]);

  const defaultTag = (tagInfo: tagInfo, unitNumber: number) => {
    const parsedTagName = parseTagPath(tagInfo);
    const cpy = { ...tagInfo };
    if (standardTags.includes(parsedTagName)) {
      if (tagInfo.tagpath.includes(`u${unitNumber}`)) {
        cpy.defaultTag = true;
        return cpy;
      }
    }
    cpy.defaultTag = false;
    return cpy;
  };

  useEffect(() => {
    getTaglist();
    getDeviceInfo();
    getRTtagInfo();
  }, [getDeviceInfo,getRTtagInfo,getTaglist]);

  if (data === null) return <p>Loading</p>;
  // console.log(data)
  return (
    <div className="App">
      <h1>CC-losant-agent-fetcher {query?.deviceId}</h1>
      <DeviceInfo
        deviceId={query?.deviceId}
        unitNumber={unitNumber}
        deviceInfo={deviceInfo}
      />

      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column === "Id" ? direction : null}
              onClick={() => dispatch({ type: "CHANGE_SORT", column: "Id" })}
            >
              Id
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "tagPath" ? direction : null}
              onClick={() =>
                dispatch({ type: "CHANGE_SORT", column: "tagPath" })
              }
            >
              tagpath
            </Table.HeaderCell>
            <Table.HeaderCell>Losant Attribute</Table.HeaderCell>
            <Table.HeaderCell>datatype</Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "defaultTag" ? direction : null}
              onClick={() =>
                dispatch({ type: "CHANGE_SORT", column: "defaultTag" })
              }
            >
              defaultTag
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "fetchRealTime" ? direction : null}
              onClick={() =>
                dispatch({ type: "CHANGE_SORT", column: "fetchRealTime" })
              }
            >
              fetchRealTime
            </Table.HeaderCell>
            {/* <Table.HeaderCell>lastAttempt</Table.HeaderCell> */}
            {/* <Table.HeaderCell>lastFetch</Table.HeaderCell> */}
            <Table.HeaderCell>fetchCount</Table.HeaderCell>
            {/* <Table.HeaderCell sorted={column === 'fetchS3' ? direction : null} onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'fetchS3' })}>fetchS3</Table.HeaderCell>
        <Table.HeaderCell sorted={column === 'fetchTimestream' ? direction : null} onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'fetchTimestream' })}>fetchTimestream</Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data?.map((e: tagInfo, i: number) => (
            <Tag
              key={i}
              tagInfo={e}
              deviceId={query.deviceId}
              newInfo={dispatch}
              unitNumber={unitNumber}
            />
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="7"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
}

export default TagListPage;
