import React, { useCallback, useState } from 'react'
import { orderType } from '../types/order';
import { apiBaseUrl, apiKey } from '../config';
import axios from 'axios';
import { Input } from 'semantic-ui-react';

export const useShowOrEdit = (
        fieldType: keyof orderType,
        order: orderType,
        set_order: any,
        fontSize: number,
        show: boolean,
        label?: string,
      ) => {
        const [edit, set_edit] = useState(false);
        const [info, set_info] = useState(order?.[fieldType]);
    
        const ClickHandler = (event: any) => {
          if (event.detail === 2) {
            if(localStorage.getItem("edit")){
              set_edit(true);
            }
          }
        };
        const handleKeyPress = (event: any) => {
          if (event.key === "Enter") {
            changeOrderType();
          }
        };
    
        const changeOrderType = useCallback(async () => {
          try {
            const res = await axios.put(
              `${apiBaseUrl}/api/v0/order/${order.id}`,
              { [fieldType]: info },
              { headers: { "x-api-key": apiKey } }
            );
            set_order(res.data);
            set_edit(false);
          } catch (err) {
            console.log("err", err);
          }
        }, [order.id, info, fieldType, set_order]);
        
        
    
        // console.log("info", label ,info, typeof info)
        if(!show) return null
        return <React.Fragment> 
          {label? <label style={{ margin: "5px" }}>{label}:</label> : null}
          { !edit ? (
          <p
            style={{
              fontSize: `${fontSize}px`,
              marginLeft: "10px",
              marginBottom: "5px",
            }}
            onClick={ClickHandler}
          >
            {typeof info === "string" ? info : null}
          </p>
        ) : (
          <Input
            iconPosition="left"
            id="dropdownSearch"
            value={info}
            className="search"
            onChange={(e, { value }) => set_info(value)}
            onKeyPress={handleKeyPress}
          />
        ) }
        </React.Fragment>
      };
