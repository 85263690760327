import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'
import CheckToken from './CheckToken'

export default function Navigation() {
  const navigate = useNavigate()
  const edit = localStorage.getItem("edit")
  return (
    <Menu>
        <CheckToken/>
        <Menu.Item header>Cavotec Connect</Menu.Item>
        <Menu.Item
          name='Order Tracker'
          onClick={() => navigate("/orders")}
        />
        <Menu.Item
          name='prioritizedProducts'
          onClick={() => navigate("/prioritizedProducts")}
        />
        <Menu.Item
          name='actions'
          onClick={() => navigate("/actions")}
        />
        { edit ? <Menu.Item
          name='Fetcher(app)'
          onClick={() => navigate("/fetcher")}
        /> : null}
        <Menu.Item
          name='Account'
          onClick={() => navigate("/authToken")}
        />
      </Menu>
  )
}
