import React, { useCallback, useEffect, useState } from 'react'
import Order from '../components/Order'
import axios from 'axios'
import { Dimmer, Loader } from 'semantic-ui-react'
import { apiBaseUrl, apiKey } from '../config'
import { useParams } from 'react-router-dom'
import OrderContents from '../components/OrderContents'
import {orderType} from '../types/order'
import OrderComments from '../components/OrderComments'
import OrderTasks from '../components/OrderTasks'
import OrderActions from '../components/OrderActions'

export default function OrderTrackerEditPage() {
  const [order, set_order] = useState<null| orderType>(null)
  const params = useParams()
  
  const getData = useCallback( async () => {
    try{
      const res= await axios.get(`${apiBaseUrl}/api/v0/order/${params.orderId}`,  {  headers: { 'x-api-key': apiKey}})
      set_order(res.data)
    }catch(err){
      console.log("err",err)
    }
  },[params.orderId])
  useEffect(()=> {
    getData()
  }, [getData])
  const edit = localStorage.getItem("edit")

  const [abbreviations, set_abbreviations] = useState([])
    const getAbbreviations = useCallback(async () => {
        try {
          const res = await axios.get(`${apiBaseUrl}/api/v0/abbreviations`, {
            headers: { "x-api-key": apiKey },
          });
          set_abbreviations(res?.data);
        } catch (err) {
          console.log("err", err);
        }
      }, []);

      
  useEffect(() => {
    getAbbreviations();
  }, [getAbbreviations]);

  if(order ===  null ) {return      <Dimmer active>  <Loader /></Dimmer> }
  return (
    <div>
      <h1>OrderDetails</h1>
          <Order  key={2} 
                  siteName={order.siteName} 
                  order={order} 
                  edit={edit? true : false} 
                  set_order={set_order}
                  abbreviations={abbreviations}
                  />
          <OrderContents order={order} set_order={set_order}/>
          <OrderActions order={order} set_order={set_order}/>
          <OrderComments order={order} set_order={set_order}/>
          <OrderTasks order={order} set_order={set_order}/>
    </div>
  )
}
