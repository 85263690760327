import axios from "axios";
import React, { useCallback } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { apiBaseUrl, apiKey } from "../config";
import { ccMasterPhase } from "../types/ccTypes";
import { designType } from "../types/order";
import { orderType } from "../types/order";
const displayText = require("../components/displayText.json");
interface orderContentProps {
    order: orderType;
    set_order: any
  }

  interface displayTextType {
    displayText: string
    topic: string
    phase: ccMasterPhase
    designType: designType[]
  }

export default function OrderTasks({ order,set_order }: orderContentProps) {
  
  const addProduct  = useCallback( async (todoItem: displayTextType) => {
    try{
        if(todoItem.topic && todoItem.phase){
          const add = { topicName: todoItem.topic, phase: todoItem.phase} 
            const res= await axios.put(`${apiBaseUrl}/api/v0/order/${order.id}`, add ,{  headers: { 'x-api-key': apiKey}})
            set_order(res.data)
        }
    }catch(err){
      console.log("err",err)
    }
  },[set_order, order.id])

const taskInOrder = (topicName: string, order:orderType) => {
  const topicInOrder =  order?.todoList?.filter( (todo) => todo?.topicName === topicName)
  if(topicInOrder.length >= 1) return true
  return false  
}
  
  if(!localStorage.getItem("edit")) return null
  return (
    <div>
      <h2>OrderTasks</h2>
      <p>In this section you can add tasks that need to be executed for this specfic project</p>
      <Table celled>
        <Table.Header>
          <Table.Row>
            {/* <Table.HeaderCell>data tag</Table.HeaderCell> */}
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Phase</Table.HeaderCell>
            <Table.HeaderCell>In order</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          
          { displayText?.map((todoItem: displayTextType, i: number) => {
            return (
              <Table.Row key={i}>
                {/* <Table.Cell>{todoItem?.topic}</Table.Cell> */}
                <Table.Cell>{todoItem?.displayText}</Table.Cell>
                <Table.Cell>{todoItem?.phase}</Table.Cell>
                <Table.Cell>{ taskInOrder(todoItem?.topic,order) ? <Icon name="checkmark"/> :<Button primary onClick={()=> addProduct(todoItem)}>Add</Button>}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="4"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
}
